import styled, { css } from 'styled-components';

export const RadioContainer = styled.div`
  margin-top: 16px;
`;

export const IconContainer = styled.div`
  width: 28px;
  height: 28px;
  padding: 2px;
  position: relative;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  place-content: center;
  background-color: ${({ isSelected, theme }) =>
    isSelected ? theme.colorWhite : theme.colorBackground08};
  border-radius: 100%;
  & > svg {
    color: ${({ isSelected, theme }) =>
      isSelected ? theme.colorRed : theme.colorBackground01};
  }
`;

export const CheckMarkContainer = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: -6px;
  bottom: -2px;
  width: 16px;
  height: 16px;
  padding: 2px;
  border: 2px solid white;
  border-radius: 100%;
  background-color: ${({ theme }) => theme.colorGreenMood100};
  & > svg {
    color: white;
    width: 12px;
    height: 12px;
  }
`;

export const RadioItem = styled.button`
  position: relative;
  display: flex;
  gap: 10px;
  align-items: center;

  font-family: ${({ theme }) => theme.fontFamilyHeading};
  color: ${({ isSelected, theme }) =>
    isSelected ? theme.colorWhite : theme.colorBackground01};
  background-color: ${({ isSelected, theme }) =>
    isSelected ? theme.colorRed : theme.colorWhite};
  font-size: 16px;
  line-height: 22px;

  cursor: initial;
  padding: 13px 23px;
  width: fit-content;

  border: 1px solid rgba(0, 0, 0, 0.3);
  border-color: ${({ isSelected, theme }) =>
    isSelected ? theme.colorRed : theme.vibeRadioBorderColor};
  border-radius: 10px;

  &:not(:last-of-type) {
    margin-bottom: 8px;
  }

  transition: 150ms ease-in all;
  &:enabled {
    cursor: pointer;
    ${({ isSelected }) =>
      !isSelected &&
      css`
        &:hover {
          border-color: ${({ theme }) => theme.vibeRadioBackgroundColorHover};
          background-color: ${({ theme }) =>
            theme.vibeRadioBackgroundColorHover};
          color: ${({ theme }) => theme.colorWhite};
        }
      `}
  }
`;
