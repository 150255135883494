import styled from 'styled-components';
import mediaQuery from 'utils/mediaQuery';
const { lessThan } = mediaQuery;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
`;

export const CalendarContent = styled.div`
  display: flex;
  max-width: 100%;

  .rdp {
    margin: 0;
  }
  .rdp-day_selected:not([aria-disabled='true']),
  .rdp-day_selected:focus:not([aria-disabled='true']),
  .rdp-day_selected:active:not([aria-disabled='true']),
  .rdp-day_selected:hover:not([aria-disabled='true']) {
    background-color: ${({ theme }) => theme.colorBackground01} !important;
    color: ${({ theme }) => theme.colorWhite} !important;
  }

  .rdp-day_selected:not([aria-disabled='true']) {
  }

  .rdp-button:hover:not([aria-disabled='true']) {
    background-color: transparent;
    border-color: ${({ theme }) => theme.colorBackground01};
    color: ${({ theme }) => theme.colorBackground01};
  }

  ${lessThan('medium')`
    .rdp-cell {
      width: 30px;
      height: 30px;
    }

    .rdp-cell .rdp-day {
      width: 30px;
      height: 30px;
    }
  `};
`;

export const MonthGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 10px;
  padding-top: 16px;
`;

export const MonthGridItem = styled.button`
  all: unset;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  height: 46px;
  width: 46px;

  border: 2px solid transparent;
  border-radius: 50%;

  &:hover {
    border-color: ${({ theme }) => theme.vibeRadioBackgroundColorHover};
    background-color: ${({ theme }) => theme.vibeRadioBackgroundColorHover};
    color: ${({ theme }) => theme.colorWhite};
  }

  &:disabled {
    opacity: 0.25;
    pointer-events: none;
  }

  &.selected {
    background-color: ${({ theme }) => theme.colorRed};
    color: ${({ theme }) => theme.colorWhite};

    &:hover {
      border-color: ${({ theme }) => theme.colorRed};
    }
  }

  &.today {
    font-weight: 700;
  }
`;
