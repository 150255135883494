import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';

import { scrollToBottomOfMessageHistory } from 'utils/scroll';
import { submitMessage } from 'features/Board/board.slice';

import {
  CalendarContent,
  Container,
  MonthGrid,
  MonthGridItem,
} from './MonthPicker.style';

function MonthPicker({ message }) {
  const { id, value } = message;

  const dispatch = useDispatch();
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [selectedMonth, setSelectedMonth] = useState(value || null);

  // set currentYear to next year if we are in december
  useEffect(() => {
    const currentMonth = new Date().getMonth();
    if (currentMonth < 11) {
      return;
    }
    setCurrentYear(new Date().getFullYear() + 1);
  }, [setCurrentYear]);

  useEffect(() => {
    setTimeout(() => scrollToBottomOfMessageHistory(), 200);
  }, []);

  const onChange = useCallback(
    (month, isDisabled) => {
      if (isDisabled) {
        return;
      }

      setSelectedMonth(month);
      dispatch(submitMessage(id, month));
    },
    [dispatch, setSelectedMonth, id],
  );

  const onClickNextYear = useCallback(() => {
    setCurrentYear(currentYear + 1);
  }, [currentYear, setCurrentYear]);

  const onClickPreviousYear = useCallback(() => {
    setCurrentYear(currentYear - 1);
  }, [currentYear, setCurrentYear]);

  const monthGridItems = useMemo(() => {
    const now = dayjs();

    const minDate = now.startOf('month');
    const baseDate = now.year(currentYear).startOf('year').add(12, 'hours');

    const currentDateKey = now.format('MMYYYY');

    const gridItems = [];
    for (let i = 0; i < 12; i++) {
      const currentMonth = baseDate.add(i, 'month');
      const monthKey = `${currentMonth.format('MM')}${currentYear}`;
      const isSelected = monthKey === selectedMonth;
      const isDisabled =
        minDate.isAfter(currentMonth, 'month') ||
        minDate.isSame(currentMonth, 'month');

      const monthClasses = [];
      if (isSelected) {
        monthClasses.push('selected');
      }
      if (currentDateKey === monthKey) {
        monthClasses.push('today');
      }

      gridItems.push(
        <MonthGridItem
          key={monthKey}
          onClick={() => onChange(monthKey, isDisabled)}
          className={monthClasses}
          disabled={isDisabled}
        >
          {currentMonth.format('MMM')}
        </MonthGridItem>,
      );
    }
    return gridItems;
  }, [currentYear, selectedMonth, onChange]);

  return (
    <Container>
      <CalendarContent>
        <div className="rdp">
          <div className="rdp-months">
            <div className="rdp-month rdp-caption_start rdp-caption_end">
              <div className="rdp-caption">
                <h2
                  className="rdp-caption_label"
                  aria-live="polite"
                  aria-atomic="true"
                >
                  {currentYear}
                </h2>
                <div className="rdp-nav">
                  <button
                    className="rdp-button_reset rdp-button rdp-nav_button rdp-nav_button_previous"
                    aria-disabled="false"
                    type="button"
                    onClick={onClickPreviousYear}
                  >
                    <svg
                      width="16px"
                      height="16px"
                      viewBox="0 0 120 120"
                      className="rdp-nav_icon"
                    >
                      <path
                        d="M69.490332,3.34314575 C72.6145263,0.218951416 77.6798462,0.218951416 80.8040405,3.34314575 C83.8617626,6.40086786 83.9268205,11.3179931 80.9992143,14.4548388 L80.8040405,14.6568542 L35.461,60 L80.8040405,105.343146 C83.8617626,108.400868 83.9268205,113.317993 80.9992143,116.454839 L80.8040405,116.656854 C77.7463184,119.714576 72.8291931,119.779634 69.6923475,116.852028 L69.490332,116.656854 L18.490332,65.6568542 C15.4326099,62.5991321 15.367552,57.6820069 18.2951583,54.5451612 L18.490332,54.3431458 L69.490332,3.34314575 Z"
                        fill="currentColor"
                        fillRule="nonzero"
                      />
                    </svg>
                  </button>
                  <button
                    className="rdp-button_reset rdp-button rdp-nav_button rdp-nav_button_next"
                    aria-disabled="false"
                    type="button"
                    onClick={onClickNextYear}
                  >
                    <svg
                      width="16px"
                      height="16px"
                      viewBox="0 0 120 120"
                      className="rdp-nav_icon"
                    >
                      <path
                        d="M49.8040405,3.34314575 C46.6798462,0.218951416 41.6145263,0.218951416 38.490332,3.34314575 C35.4326099,6.40086786 35.367552,11.3179931 38.2951583,14.4548388 L38.490332,14.6568542 L83.8333725,60 L38.490332,105.343146 C35.4326099,108.400868 35.367552,113.317993 38.2951583,116.454839 L38.490332,116.656854 C41.5480541,119.714576 46.4651794,119.779634 49.602025,116.852028 L49.8040405,116.656854 L100.804041,65.6568542 C103.861763,62.5991321 103.926821,57.6820069 100.999214,54.5451612 L100.804041,54.3431458 L49.8040405,3.34314575 Z"
                        fill="currentColor"
                      />
                    </svg>
                  </button>
                </div>
              </div>
              <MonthGrid>{monthGridItems}</MonthGrid>
            </div>
          </div>
        </div>
      </CalendarContent>
    </Container>
  );
}

export default MonthPicker;
