import dayjs from 'dayjs';

const holidays = [
  new Date('2021-01-01'),
  new Date('2021-01-06'),
  new Date('2021-04-02'),
  new Date('2021-04-05'),
  new Date('2021-05-01'),
  new Date('2021-05-13'),
  new Date('2021-05-24'),
  new Date('2021-06-03'),
  new Date('2021-08-01'),
  new Date('2021-08-15'),
  new Date('2021-11-01'),
  new Date('2021-12-08'),
  new Date('2021-12-25'),
  new Date('2021-12-26'),
  new Date('2022-01-01'),
  new Date('2022-01-02'),
];

// shorthand to facilitate testing
function getNow() {
  return dayjs();
}

export function getMinDate(dateFormat) {
  const minDate = getNow();

  if (
    minDate?.hour() > 11 ||
    (minDate?.hour() === 11 && minDate.minute() >= 30)
  ) {
    minDate.add(1, 'd');
  }

  // check if the date is a disabled date
  const disabledDays = [...holidays] || [];
  const minDateStr = minDate.clone().startOf('day').format('YYYY-MM-DD');

  const isHoliday = disabledDays
    .map((date) => dayjs(date).format('YYYY-MM-DD'))
    .includes(minDateStr);

  if (isHoliday) {
    minDate.add(1, 'd');
  }

  // check if the date is a weekend
  if (minDate.day() === 6) {
    minDate.add(2, 'd');
  }
  if (minDate.day() === 0) {
    minDate.add(1, 'd');
  }

  // check if it should be simple return
  if (!dateFormat) {
    return minDate.toISOString();
  }
  return minDate.format(dateFormat);
}
