import PropTypes from 'prop-types';

import { SpriteIcon } from 'components';

import { useTranslation, Trans } from 'react-i18next';

import {
  OfferContainer,
  Main,
  IconContainer,
  TextContainer,
  Description,
} from '../../Offer.style';
import NumberFormat from 'react-number-format';
import thousandSeparator from 'utils/thousandSeparator';

function KnockoutNumber({ value }) {
  return (
    <NumberFormat
      prefix={'CHF '}
      suffix={''}
      thousandSeparator={thousandSeparator(value)}
      value={value}
      displayType="text"
    />
  );
}

function Knockout({ combinedKnockout, offerContainerRef }) {
  const { t } = useTranslation();
  if (!combinedKnockout) {
    return <></>;
  }

  let knockoutTitle = '';
  let knockoutContent = '';

  if (combinedKnockout?.knockout === 'Affordability') {
    knockoutTitle = (
      <Description bold>
        <Trans
          i18nKey="vibe.knockout.affordability.title"
          components={{
            FormattedNumber: (
              <KnockoutNumber
                value={combinedKnockout?.knockoutThresholdIncome}
              />
            ),
          }}
        ></Trans>
      </Description>
    );
    knockoutContent = (
      <Description>
        <Trans
          i18nKey="vibe.knockout.affordability.content"
          components={{
            FormattedNumber: (
              <KnockoutNumber
                value={combinedKnockout?.knockoutThresholdIncome}
              />
            ),
          }}
        ></Trans>
      </Description>
    );
  }

  if (combinedKnockout?.knockout === 'Location') {
    knockoutTitle = (
      <Description bold>{t('vibe.knockout.location.title')}</Description>
    );
    knockoutContent = (
      <Description>{t('vibe.knockout.location.content')}</Description>
    );
  }

  if (combinedKnockout)
    return (
      <OfferContainer ref={offerContainerRef}>
        <Main knockout>
          <IconContainer>
            <SpriteIcon name="notification-info-20" />
          </IconContainer>
          <TextContainer>
            {knockoutTitle}
            {knockoutContent}
            <Description>
              {t('vibe.knockout.description')}
              <br />
              <a href="tel:0800873877">0800 873 877</a>{' '}
              {t('vibe.knockout.openingHours')}
            </Description>
          </TextContainer>
        </Main>
      </OfferContainer>
    );
}

Knockout.propTypes = {
  message: PropTypes.object,
};

export default Knockout;
