import PropTypes from 'prop-types';
import { Dialog } from '@headlessui/react';
import styled from 'styled-components';

import Header from 'components/Header';
import MessageHistory from '../MessageHistory';
import ChatBox from '../ChatBox';

const StyledDialog = styled(Dialog)`
  z-index: 100;
  position: relative;
`;

const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  background-color: #fff;
`;

const Wrapper = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;

  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  width: 100%;
  height: 100%;

  #eva-vibe-mc {
    display: flex;
    flex: 100% 1 1;

    padding-left: 12px;
    padding-right: 12px;
  }

  .vibe-chat-box {
    padding-bottom: 12px;
  }
`;

const StyledDialogPanel = styled(Dialog.Panel)`
  display: flex;
  flex: 100% 1 0;
  flex-direction: column;

  width: 100%;
  height: 100%;
`;

function MobileDialog({ isModalOpen, onModalClose }) {
  return (
    <StyledDialog open={isModalOpen} onClose={onModalClose}>
      <ModalBackdrop aria-hidden="true" />
      <Wrapper>
        <StyledDialogPanel>
          <Header isModal onModalClose={onModalClose} />
          <MessageHistory />
          <ChatBox />
        </StyledDialogPanel>
      </Wrapper>
    </StyledDialog>
  );
}

MobileDialog.propTypes = {
  isModalOpen: PropTypes.bool,
  onModalClose: PropTypes.func.isRequired,
};

export default MobileDialog;
