export const scrollIntoView = (id) => {
  const messageContanier = document.querySelector('#eva-vibe-mc');
  if (!messageContanier) {
    return;
  }

  const scrollOffset =
    document.getElementById(id).offsetTop - messageContanier.offsetHeight + 40;
  messageContanier.scrollTop = scrollOffset || 0;
};

export function scrollToBottomOfMessageHistory() {
  const messageContanier = document.querySelector('#eva-vibe-mc');
  if (!messageContanier) {
    return;
  }
  messageContanier.scrollTop = messageContanier.scrollHeight;
}
