/**
 *
 * Header
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import media from 'utils/mediaQuery';
import theme from 'utils/theme';

import SpriteIcon from 'components/SpriteIcon';
import { StickyOffer } from 'features/Board';
import { useSelector } from 'react-redux';
import { selectIsModalOpen } from 'features/Board/board.slice';
import { selectIsStickyOfferVisible } from 'features/Offer/Offer.slice';

const HeaderWrapper = styled.div`
  display: flex;
  align-items: flex-end;

  width: 100%;
  position: relative;

  background-color: ${theme.colorIntroHeaderBackground};
  padding: ${theme.paddingCard};

  &:after {
    display: none;

    top: 99%;
    left: 50%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(138, 70, 144, 0);
    border-top-color: ${theme.colorIntroHeaderBackground};
    border-width: 14px;
    margin-left: -14px;
    z-index: 1;
  }

  &.modal {
    padding: 14px 17px 14px 0;
    background-color: ${theme.colorWhite};
  }

  &.transparent {
    background-color: ${theme.colorWhite} !important;
    h4 {
      color: transparent !important;
    }
  }
`;

const HeaderMainWrapper = styled.div`
  display: flex;
  flex: 100% 1 0;
  flex-direction: column;

  justify-content: space-between;
  align-items: flex-start;

  ${media.lessThan('small')`
    flex-basis: 100%;
    justify-content: flex-start;
  `};
`;

const HeaderTitle = styled.div`
  width: 100%;

  &.modal {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    h4 {
      display: none;
    }
  }

  h4 {
    font-family: ${theme.fontFamilyHeading};
    font-size: 24px !important;
    line-height: 28px !important;
    color: ${theme.colorIntroHeaderText};
    margin-top: 20px;

    ${media.lessThan('small')`
      font-size: 20px !important;
      line-height: 24px !important;
      margin-top: 0;
   `};
  }

  ${media.lessThan('small')`
     width: 100%;
     text-align: center;
  `};
`;

const HeaderCloseButton = styled.button`
  all: unset;

  font-size: 18px;
  height: 18px;
`;

function Header({ isModal, onModalClose }) {
  const { t } = useTranslation();

  const isModalOpen = useSelector(selectIsModalOpen);
  const isStickyOfferVisible = useSelector(selectIsStickyOfferVisible);

  if (!isModal && isModalOpen) {
    return <></>;
  }

  const isTransparent = !isModal && isStickyOfferVisible;

  return (
    <>
      <HeaderWrapper
        id="eva-vibe-header"
        className={`${isModal && 'modal'} ${isTransparent && 'transparent'}`}
      >
        <HeaderMainWrapper className={isModal && 'modal'}>
          <HeaderTitle className={isModal && 'modal'}>
            <h4>{t('vibe.general.title')}</h4>
            {isModal && (
              <HeaderCloseButton onClick={onModalClose}>
                <SpriteIcon name="close-button-large-20" />
              </HeaderCloseButton>
            )}
          </HeaderTitle>
        </HeaderMainWrapper>
      </HeaderWrapper>
      {(isModal || !isModalOpen) && <StickyOffer isModal={isModal} />}
    </>
  );
}

Header.propTypes = {
  isModalOpen: PropTypes.bool,
  onModalClose: PropTypes.func,
};

export default Header;
