import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  OfferContainer,
  LoaderContainer,
  LoaderText,
  Main,
} from '../../Offer.style';

function OfferLoader({ isTopConditions }) {
  const { t } = useTranslation();

  return (
    <OfferContainer>
      <Main>
        <LoaderContainer className="a-loader a-loader--inline state-a-loader--visible">
          <div className="preloader-wrapper active">
            <div className="spinner-layer">
              <div className="circle-clipper left">
                <div className="circle"></div>
              </div>
              <div className="gap-patch">
                <div className="circle"></div>
              </div>
              <div className="circle-clipper right">
                <div className="circle"></div>
              </div>
            </div>
          </div>
        </LoaderContainer>
        {isTopConditions && (
          <LoaderText>{t('vibe.offer.topConditions.loading')}</LoaderText>
        )}
        {!isTopConditions && (
          <LoaderText>{t('vibe.offer.dailyOffer.loading')}</LoaderText>
        )}
      </Main>
    </OfferContainer>
  );
}

OfferLoader.propTypes = {
  isTopConditions: PropTypes.bool,
};

export default OfferLoader;
