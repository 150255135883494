import styled from 'styled-components';
import mediaQuery from 'utils/mediaQuery';
const { lessThan } = mediaQuery;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
`;

export const CalendarContent = styled.div`
  display: flex;
  max-width: 100%;

  .rdp {
    margin: 0;
  }
  .rdp-day_selected:not([aria-disabled='true']),
  .rdp-day_selected:focus:not([aria-disabled='true']),
  .rdp-day_selected:active:not([aria-disabled='true']) {
    background-color: ${({ theme }) => theme.colorRed} !important;
    color: ${({ theme }) => theme.colorWhite} !important;
  }

  .rdp-day_selected:not([aria-disabled='true']) {
  }

  .rdp-button:hover:not([aria-disabled='true']) {
    background-color: ${({ theme }) => theme.vibeRadioBackgroundColorHover};
    border-color: ${({ theme }) => theme.vibeRadioBackgroundColorHover};
    color: ${({ theme }) => theme.colorWhite};
  }

  .rdp-day_selected:hover:not([aria-disabled='true']),
  .rdp-day_selected:focus:not([aria-disabled='true']) {
    border-color: ${({ theme }) => theme.colorRed} !important;
  }

  ${lessThan('medium')`
    .rdp-cell {
      width: 30px;
      height: 30px;
    }

    .rdp-cell .rdp-day {
      width: 30px;
      height: 30px;
    }
  `};
`;
