import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components/macro';
import 'whatwg-fetch';

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import GlobalStyle from 'global-styles';
import store from 'global-store';
import theme from 'utils/theme';

import App from 'app';

dayjs.extend(customParseFormat);

function getEvaContainer() {
  return document.getElementById('b67ef2a0483b');
}

let MOUNT_NODE = getEvaContainer();

function render() {
  const evaContainer = MOUNT_NODE || document.body;
  if (!evaContainer) {
    return;
  }

  // set settings as window variables
  try {
    window.EVA_VIBE_MODAL_MODE =
      evaContainer.getAttribute('data-eva-modal') === 'true';
  } catch (ignore) {}

  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <App />
          <GlobalStyle />
        </ThemeProvider>
      </Provider>
    </React.StrictMode>,
    evaContainer,
  );
}

render();

let hasUnavailableMountNode = false;
setInterval(() => {
  const containerItr = getEvaContainer();
  if (!containerItr) {
    hasUnavailableMountNode = true;
    ReactDOM.unmountComponentAtNode(MOUNT_NODE);
    return;
  } else if (hasUnavailableMountNode) {
    hasUnavailableMountNode = false;
    MOUNT_NODE = containerItr;
    render();
  }
}, 500);
