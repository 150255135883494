import { saveAs } from 'file-saver';

import config from 'global-config';
import evaTracking from 'utils/evaTracking';

// fetch remote data
export async function fetchDefaultOffers() {
  const requestUrl = `${config.backend.apiUrl}/v1/hpc/defaults`;
  const apiResponse = await fetch(requestUrl, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
    },
  });

  const data = await apiResponse.json();
  return { ...data?.redemption?.response, fetchedAt: data.fetchedAt } || {};
}

// fetch remote data
export async function fetchRedemptionOffers(requestData) {
  const requestUrl = `${config.backend.apiUrl}/v1/vibe/redemption`;
  const apiResponse = await fetch(requestUrl, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(requestData),
  });

  const data = await apiResponse.json();
  if (apiResponse.ok) {
    return data || {};
  }
  if (data.hasValidationErrors) {
    return { knockout: 'Location', interestRatesOverview: [] };
  }
  throw new Error(apiResponse.status);
}

// (generate) and download document
export async function downloadDocument(requestData) {
  evaTracking.trackDownload('vibe.pdf');
  evaTracking.appEvent('download_document');

  const requestUrl = `${config.backend.apiUrl}/v1/vibe/document`;
  const apiResponse = await fetch(requestUrl, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(requestData),
  });

  if (!apiResponse.ok) {
    throw new Error(apiResponse.status);
  }

  const blob = await apiResponse.blob();
  saveAs(blob, 'ViBe.pdf');
}
