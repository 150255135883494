import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useElementOnScreen from 'utils/useElementOnScreen';

import { MessageHistory, ChatBox, MobileDialog } from 'features/Board';
import {
  changeIsModalOpen,
  selectIsModalOpen,
  selectMessages,
  startFlow,
  continueFlow,
} from 'features/Board/board.slice';

import { BoardContainer } from './Board.style';

function Board() {
  const dispatch = useDispatch();
  const messages = useSelector(selectMessages);
  const isModalOpen = useSelector(selectIsModalOpen);

  const [boardContainerRef, isVisible] = useElementOnScreen({
    root: document.querySelector('#eva-vibe-app-container'),
    rootMargin: '0px',
    threshold: 0.5,
  });

  useEffect(() => {
    if (isVisible && !messages?.length) {
      dispatch(startFlow());
    }
  }, [dispatch, isVisible, messages]);

  // message flow control
  useEffect(() => {
    const currentMessage = messages?.[messages?.length - 1];
    if (!currentMessage || currentMessage.type !== 'MESSAGE') {
      return;
    }

    const numberOfBubbles = currentMessage?.params?.bubbles?.length;
    const delay =
      currentMessage?.params?.nextMessageDelay || 1000 * numberOfBubbles + 100;
    const continueFlowTimer = setTimeout(() => {
      dispatch(continueFlow({ messageId: currentMessage?.id }));
    }, delay);
    return () => clearTimeout(continueFlowTimer);
  }, [dispatch, messages]);

  const onModalClose = useCallback(() => {
    dispatch(changeIsModalOpen(false));
  }, [dispatch]);

  return (
    <>
      <MobileDialog isModalOpen={isModalOpen} onModalClose={onModalClose} />
      {!isModalOpen && (
        <BoardContainer className="eva-vibe-board" ref={boardContainerRef}>
          <MessageHistory />
          <ChatBox />
        </BoardContainer>
      )}
    </>
  );
}

export default Board;
