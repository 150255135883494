import { useEffect } from 'react';
import styled from 'styled-components';
import i18n from 'i18next';

import dayjs from 'dayjs';
import 'dayjs/locale/de-ch';
import 'dayjs/locale/fr-ch';
import 'dayjs/locale/it-ch';
import 'dayjs/locale/en';

import 'rc-slider/assets/index.css';

import evaTracking from 'utils/evaTracking';
import { getLanguage } from 'utils/browser';

import { Board } from 'pages';
import VersionNumber from 'components/VersionNumber';
import Header from 'components/Header';

import './i18n';

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export default function App() {
  useEffect(() => {
    evaTracking.setAppName('vibe');
  }, []);

  useEffect(() => {
    const lang = getLanguage();
    i18n.changeLanguage(lang);

    let dayjsLang = `${lang}-ch`;
    if (lang === 'en') {
      dayjsLang = 'en';
    }
    dayjs.locale(dayjsLang);
  }, []);

  return (
    <AppContainer
      className="eva-vibe-app-container"
      id="eva-vibe-app-container"
    >
      <Header />
      <Board />
      {process.env.REACT_APP_INTEGRATED !== 'true' && <VersionNumber />}
    </AppContainer>
  );
}
