const baseMortgageDurations = [3, 5, 8, 10, 12, 15, 20, 25];
const mortgageDurationCount = baseMortgageDurations.length;

function calculateMedian(arr) {
  const mid = Math.floor(arr.length / 2),
    nums = [...arr].sort((a, b) => a - b);
  return arr.length % 2 !== 0 ? nums[mid] : (nums[mid - 1] + nums[mid]) / 2;
}

export default function filterMortgageDurations(offers, selectedOffer) {
  if (!offers || offers?.length === 0) {
    return baseMortgageDurations;
  }

  const selectedDuration = selectedOffer?.duration || offers?.[0].duration;

  // add all available durations
  let durations = [];
  baseMortgageDurations.forEach((durationItr) => {
    const durationOffer = offers?.find(
      (offerItr) => offerItr.duration === durationItr,
    );
    if (durationOffer) {
      durations.push(durationOffer.duration);
    }
  });

  // check if selected duration is not yet added
  if (
    !durations
      .map((durationItr) => durationItr.duration)
      .includes(selectedDuration)
  ) {
    durations.push(selectedOffer.duration);
  }

  // sort by durations
  durations = durations.sort((a, b) => a - b);

  // check if durations has correct length
  if (durations.length <= baseMortgageDurations.length) {
    return durations;
  }

  // check if we need to remove one offer
  if (durations.length > baseMortgageDurations.length) {
    const medianBaseDuration = calculateMedian(baseMortgageDurations);
    if (medianBaseDuration < selectedDuration) {
      durations = durations.slice(1);
    } else {
      durations.pop();
    }
    return durations;
  }

  // sort the left over offers and fill up array
  const unselectedDurations = [];

  offers.forEach((offerItr) => {
    if (durations.includes(offerItr.duration)) {
      return;
    }
    if (offerItr.duration < selectedDuration) {
      unselectedDurations.push(offerItr.duration);
    }
  });

  // calculate how many durations are missing to fill the array
  const missingDurationsCount = mortgageDurationCount - durations.length;

  if (missingDurationsCount > unselectedDurations.length) {
    durations = durations.concat(unselectedDurations);
  } else {
    if (selectedDuration > 7) {
      durations = durations.concat(
        unselectedDurations.slice(-missingDurationsCount),
      );
    } else {
      durations = durations.concat(
        unselectedDurations.slice(0, missingDurationsCount),
      );
    }
  }

  return durations.sort((a, b) => a - b);
}
