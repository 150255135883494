import React, { useRef, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Tooltip from 'rc-tooltip';

import raf from 'rc-util/lib/raf';
import 'rc-tooltip/assets/bootstrap.css';

function HandleTooltip({ value, children, visible, ...restProps }) {
  const tooltipRef = useRef();
  const rafRef = useRef(null);
  const { t } = useTranslation();

  const tipFormatter = useCallback(
    (val) => `${val} ${t('vibe.offer.years')}`,
    [t],
  );

  function cancelKeepAlign() {
    raf.cancel(rafRef.current);
  }

  function keepAlign() {
    rafRef.current = raf(() => {
      tooltipRef.current?.forcePopupAlign();
    });
  }

  useEffect(() => {
    if (visible) {
      keepAlign();
    } else {
      cancelKeepAlign();
    }
    return cancelKeepAlign;
  }, [value, visible]);

  return (
    <Tooltip
      placement="top"
      overlay={tipFormatter(value)}
      overlayInnerStyle={{
        minHeight: 'auto',
        fontSize: '14px',
        padding: '6px 10px',
        borderRadius: '5px',
      }}
      ref={tooltipRef}
      visible={visible}
      {...restProps}
    >
      {children}
    </Tooltip>
  );
}

export const handleRender = (node, props, containerClassName) => {
  return (
    <HandleTooltip
      value={props.value}
      visible={true}
      getTooltipContainer={() =>
        document.querySelector(`.${containerClassName}`)
      }
    >
      {node}
    </HandleTooltip>
  );
};
