import styled from 'styled-components';

export const TypographyContainer = styled.p`
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  white-space: pre-wrap;
  color: ${(props) => props.theme.colorBackground01};
`;
