import { useEffect, useMemo, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AnimatePresence } from 'framer-motion';

import { scrollToBottomOfMessageHistory } from 'utils/scroll';

import { Message } from '../';
import { selectMessages } from '../board.slice';
import {
  selectIsStickyOfferVisible,
  selectIsOfferCollapsed,
  changeIsOfferCollapsed,
} from '../../Offer/Offer.slice';

import { MessageHistoryContainer } from './MessageHistory.style';

const MessageHistory = () => {
  const isStickyOfferVisible = useSelector(selectIsStickyOfferVisible);
  const isOfferCollpased = useSelector(selectIsOfferCollapsed);
  const messages = useSelector(selectMessages);
  const bottomRef = useRef(null);
  const dispatch = useDispatch();

  const handleScroll = () => {
    if (isStickyOfferVisible && !isOfferCollpased) {
      dispatch(changeIsOfferCollapsed(true));
    }
  };

  const messageComponents = useMemo(() => {
    const currentVibeMessage = messages
      ?.filter(
        (messageItr) =>
          messageItr?.type === 'MESSAGE' || messageItr?.type === 'OFFER',
      )
      ?.pop();

    return messages
      ?.filter(
        (messageItr) =>
          messageItr?.params?.interactionType !== 'INPUT' ||
          messageItr.value !== undefined,
      )
      ?.map((message, idx) => (
        <Message
          key={message?.id}
          message={message}
          isActive={currentVibeMessage?.id === message?.id}
          isCurrent={messages?.length - 1 === idx}
        />
      ));
  }, [messages]);

  useEffect(() => {
    scrollToBottomOfMessageHistory();
  }, [messages]);

  return (
    <MessageHistoryContainer id="eva-vibe-mc" onScroll={handleScroll}>
      <AnimatePresence>{messageComponents}</AnimatePresence>
      <div id="eva-vibe-mcb" ref={bottomRef} />
    </MessageHistoryContainer>
  );
};

export default MessageHistory;
