import { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';

import useElementOnScreen from 'utils/useElementOnScreen';
import evaTracking from 'utils/evaTracking';

import { Typography, Slider, SpriteIcon } from 'components';
import {
  continueFlow,
  scrollToBottom,
  selectCalculationData,
} from 'features/Board/board.slice';

import OfferLoader from './components/OfferLoader';
import Knockout from './components/Knockout';

import {
  ItalicLabel,
  OfferContainer,
  Main,
  TitleContainer,
  FigureContainer,
  FigureItem,
  FigureValue,
  Title,
  ThumbsUpButton,
  ThumbsUpIcon,
  SliderContainer,
  getSliderStyle,
  DisclaimerContainer,
  DesktopActionWrapper,
  MobileActionWrapper,
} from './Offer.style';
import { findSliderSettings, displayInterestRate } from './Offer.helpers';
import {
  selectIsLoading,
  selectInterestRates,
  selectSaronRate,
  selectSelectedFixedRate,
  fetchDefaultOffer,
  changeSelectedFixedRate,
  changeIsOfferRendered,
  changeIsOfferVisible,
  changeIsOfferAccepted,
  fetchRedemptionOffer,
  selectCombinedKnockout,
  changeIsOfferCollapsed,
  selectIsOfferCollapsed,
  selectHasError,
  selectFetchedAt,
  selectIsStickyOfferVisible,
} from './Offer.slice';

function Offer({ message, isSticky }) {
  const { isTopConditions } = message?.params || {};

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useTheme();
  const isLoading = useSelector(selectIsLoading);
  const fetchedAt = useSelector(selectFetchedAt);
  const hasError = useSelector(selectHasError);
  const interestRates = useSelector(selectInterestRates);
  const combinedKnockout = useSelector(selectCombinedKnockout);
  const saronRate = useSelector(selectSaronRate);
  const selectedFixedRate = useSelector(selectSelectedFixedRate);
  const calculationData = useSelector(selectCalculationData);
  const isOfferCollapsed = useSelector(selectIsOfferCollapsed);
  const isStickyOfferVisible = useSelector(selectIsStickyOfferVisible);

  const isMessage = !!message?.id;
  const isCollapsed = isOfferCollapsed || (!isSticky && isStickyOfferVisible);
  const formattedFetchedAt = new Date(fetchedAt).toLocaleDateString('de-CH');

  const [offerContainerRef, isVisible] = useElementOnScreen({
    root: document.querySelector('#eva-vibe-mc'),
    rootMargin: '0px',
    threshold: 0,
  });

  useEffect(() => {
    if (!isMessage) return;

    if (isTopConditions) {
      dispatch(fetchDefaultOffer());
    } else {
      dispatch(fetchRedemptionOffer(calculationData));
    }
  }, [dispatch, isTopConditions, isMessage, calculationData]);

  useEffect(() => {
    if (!isMessage) return;

    dispatch(changeIsOfferVisible(isVisible));
  }, [dispatch, isMessage, isVisible]);

  // inform state about the rendering
  useEffect(() => {
    if (!isMessage) return;

    dispatch(changeIsOfferRendered(!isTopConditions));
    return () => dispatch(changeIsOfferRendered(false));
  }, [dispatch, isMessage, isTopConditions]);

  // automatically continue on top conditions
  useEffect(() => {
    if (!isTopConditions) return;

    dispatch(continueFlow({ messageId: message?.id }));
  }, [dispatch, message, isTopConditions]);

  const sliderSettings = useMemo(() => {
    return findSliderSettings(interestRates);
  }, [interestRates]);

  const onClickThumbsup = useCallback(() => {
    dispatch(changeIsOfferAccepted(true));
    evaTracking.appEvent('offer_accepted');

    if (isMessage) {
      dispatch(changeIsOfferCollapsed(!isOfferCollapsed));
      dispatch(continueFlow({ messageId: message?.id }));
      dispatch(scrollToBottom());
    }
  }, [dispatch, isMessage, isOfferCollapsed, message]);

  const onClickEdit = useCallback(() => {
    dispatch(changeIsOfferCollapsed(!isOfferCollapsed));
    if (isOfferCollapsed) {
      evaTracking.appEvent('offer_edit');
    } else {
      evaTracking.appEvent('offer_accepted');
    }

    if (isMessage) {
      if (isOfferCollapsed) {
        dispatch(changeIsOfferAccepted(false));
      } else {
        dispatch(changeIsOfferAccepted(true));
      }
    }
  }, [dispatch, isMessage, isOfferCollapsed]);

  if (isLoading || hasError) {
    return <OfferLoader isTopConditions={isTopConditions} />;
  }

  if (combinedKnockout) {
    return (
      <Knockout
        combinedKnockout={combinedKnockout}
        offerContainerRef={offerContainerRef}
      />
    );
  }

  return (
    <OfferContainer ref={offerContainerRef}>
      <Main>
        <TitleContainer {...{ isSticky: !isMessage, isCollapsed }}>
          <Title>
            <span>
              {isTopConditions
                ? t('vibe.offer.topConditions.label')
                : t('vibe.offer.dailyOffer.label')}
            </span>
            {isCollapsed && (
              <span>{`${t('vibe.offer.runningTime')} ${
                selectedFixedRate?.duration || 0
              } ${t('vibe.offer.years')}`}</span>
            )}
          </Title>
          {!isTopConditions && (
            <>
              <DesktopActionWrapper onClick={onClickEdit}>
                {isCollapsed && <SpriteIcon name="action-edit-20" />}
                {!isCollapsed && !isMessage && <SpriteIcon name="like-20" />}
              </DesktopActionWrapper>
              {!isCollapsed && !isMessage && (
                <MobileActionWrapper onClick={onClickEdit}>
                  <SpriteIcon name="like-20" />
                </MobileActionWrapper>
              )}
            </>
          )}
        </TitleContainer>
        <FigureContainer {...{ isSticky: !isMessage, isCollapsed }}>
          <FigureItem>
            <ItalicLabel>{t('vibe.offer.morgageTypeSaron')}</ItalicLabel>
            <FigureValue>{displayInterestRate(saronRate)}</FigureValue>
          </FigureItem>
          <FigureItem className="last">
            <ItalicLabel>{t('vibe.offer.morgageTypeFixed')}</ItalicLabel>
            <FigureValue>{displayInterestRate(selectedFixedRate)}</FigureValue>
          </FigureItem>
          {!isTopConditions && isCollapsed && (
            <MobileActionWrapper onClick={onClickEdit}>
              <SpriteIcon name="action-edit-20" />
            </MobileActionWrapper>
          )}
        </FigureContainer>
        {!isCollapsed && (
          <>
            <ItalicLabel>{t('vibe.offer.chooseTerm')}</ItalicLabel>
            <SliderContainer>
              <Slider
                {...getSliderStyle(theme)}
                min={sliderSettings?.min}
                max={sliderSettings?.max}
                marks={sliderSettings?.marks}
                value={selectedFixedRate?.duration}
                onChange={(value) => dispatch(changeSelectedFixedRate(value))}
                sliderClassName={
                  !isMessage ? 'sticky-slider-container' : 'slider-container'
                }
              />
            </SliderContainer>
            <DisclaimerContainer>
              <SpriteIcon name="notification-info-20" />
              {!isTopConditions && (
                <p className="content">{t('vibe.offer.infoNote')}</p>
              )}
              {isTopConditions && (
                <p className="content">
                  {t('vibe.offer.topConditions', {
                    formattedFetchedAt: formattedFetchedAt,
                  })}
                </p>
              )}
            </DisclaimerContainer>
          </>
        )}
      </Main>
      {isMessage && !isTopConditions && !isCollapsed && (
        <ThumbsUpButton onClick={onClickThumbsup}>
          <ThumbsUpIcon>
            <SpriteIcon name="like-20" />
          </ThumbsUpIcon>
          <Typography>{t('vibe.offer.button')}</Typography>
        </ThumbsUpButton>
      )}
    </OfferContainer>
  );
}

Offer.propTypes = {
  message: PropTypes.object,
  isSticky: PropTypes.bool,
};

export default Offer;
