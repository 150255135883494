import { useState, useRef, useEffect } from 'react';

export default function useElementOnScreen(options, onVisibilityChange) {
  const containerRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const callbackFunction = (entries) => {
    const [entry] = entries;
    setIsVisible(entry.isIntersecting);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(callbackFunction, options);

    const currentElm = containerRef.current;
    if (currentElm) observer.observe(containerRef.current);
    return () => {
      if (currentElm) observer.unobserve(currentElm);
    };
  }, [containerRef, options]);

  return [containerRef, isVisible];
}
