import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import i18n from 'i18next';

import localeDateFnsDe from 'date-fns/locale/de';
import localeDateFnsIt from 'date-fns/locale/it-CH';
import localeDateFnsFr from 'date-fns/locale/fr-CH';
import localeDateFnsEn from 'date-fns/locale/en-GB';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';

import { API_URL_DEV } from 'global-config';
import { scrollToBottomOfMessageHistory } from 'utils/scroll';

import { submitMessage } from 'features/Board/board.slice';

import { CalendarContent, Container } from './Calendar.style';

const Calendar = ({ message }) => {
  const { id, value } = message;

  const dispatch = useDispatch();
  const [date, setDate] = useState(value || new Date());
  const [disabledDays, setDisabledDays] = useState([]);
  const [minDate, setMinDate] = useState(null);

  const onChange = (newDate) => {
    setDate(newDate);
    const dateStr = dayjs(newDate).format('DDMMYYYY');
    dispatch(submitMessage(id, dateStr));
  };

  useEffect(() => {
    async function fetchDisabledDates() {
      const batConfiguration = await fetch(
        `${API_URL_DEV}/v1/bat/configuration`,
      );

      const formConfiguration = await batConfiguration.json();

      // add holidays
      const disabledDays =
        formConfiguration?.holidays?.map((dateItr) => new Date(dateItr)) || [];

      // add minDate
      if (formConfiguration?.minDate) {
        disabledDays.push({
          before: new Date(formConfiguration?.minDate),
        });
      }
      setMinDate(new Date(formConfiguration?.minDate));

      // add weekends
      disabledDays.push((day) => day.getDay() === 0);
      disabledDays.push((day) => day.getDay() === 6);

      setDisabledDays(disabledDays);
    }
    fetchDisabledDates();
  }, [setDisabledDays, setMinDate]);

  useEffect(() => {
    setTimeout(() => scrollToBottomOfMessageHistory(), 200);
  }, []);

  // select picker locale
  let pickerLocale = localeDateFnsDe;
  if (i18n.language === 'fr') {
    pickerLocale = localeDateFnsFr;
  }
  if (i18n.language === 'it') {
    pickerLocale = localeDateFnsIt;
  }
  if (i18n.language === 'en') {
    pickerLocale = localeDateFnsEn;
  }

  if (!minDate) {
    return <></>;
  }

  return (
    <Container>
      <CalendarContent>
        <DayPicker
          mode="single"
          selected={date}
          onSelect={onChange}
          disabled={disabledDays}
          weekStartsOn={1}
          locale={pickerLocale}
        />
      </CalendarContent>
    </Container>
  );
};

export default Calendar;
