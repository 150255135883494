export const POSITIONS = {
  left: 'left',
  right: 'right',
};

export const MESSAGE_TYPES = {
  message: 'MESSAGE',
  interaction: 'INTERACTION',
  offer: 'OFFER',
  rating: 'RATING',
};

export const INTERACTION_TYPES = {
  input: 'INPUT',
  select: 'SELECT',
  calendar: 'CALENDAR',
  monthPicker: 'MONTH_PICKER',
};

export const INPUT_TYPES = {
  date: 'DATE',
  shortDate: 'SHORT_DATE',
};

export const getPosition = (type) => {
  return type === MESSAGE_TYPES.message ? POSITIONS.left : POSITIONS.right;
};

export const isLeft = (position) => position === POSITIONS.left;

export const isMessage = (type) => type === MESSAGE_TYPES.message;

export const isSelect = (type, interactionType) =>
  type === MESSAGE_TYPES.interaction &&
  interactionType === INTERACTION_TYPES.select;

export const isInput = (type, interactionType) =>
  type === MESSAGE_TYPES.interaction &&
  interactionType === INTERACTION_TYPES.input;

export const isRating = (type) => type === MESSAGE_TYPES.rating;

export const isCalendar = (type, interactionType, inputType) =>
  type === MESSAGE_TYPES.interaction &&
  interactionType === INTERACTION_TYPES.calendar &&
  inputType === INPUT_TYPES.date;

export const isMonthPicker = (type, interactionType, inputType) =>
  type === MESSAGE_TYPES.interaction &&
  interactionType === INTERACTION_TYPES.monthPicker &&
  inputType === INPUT_TYPES.shortDate;

export const isOffer = (type) => type === MESSAGE_TYPES.offer;
