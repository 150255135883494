import { configureStore } from '@reduxjs/toolkit';

import boardReducer from 'features/Board/board.slice';
import offerReducer from 'features/Offer/Offer.slice';

export default configureStore({
  reducer: {
    board: boardReducer,
    offer: offerReducer,
  },
});
