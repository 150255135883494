import styled, { css } from 'styled-components';

import mediaQuery from 'utils/mediaQuery';

import { isLeft } from 'features/Board/Message';

const { lessThan } = mediaQuery;

const getBackgroundColor = ({ position, theme }) =>
  isLeft(position) ? theme.colorGrey05 : theme.colorGrey04;
const isLast = ({ isLast }) => isLast || isLast === undefined;

const BubbleContainerCommonStyle = css`
  background-color: ${getBackgroundColor};
  border-radius: 10px;
  padding: 18px 24px;
  position: relative;
  margin-bottom: ${(props) => (isLast(props) ? '0' : '12px')};
  max-width: 70%;

  ${lessThan('medium')`
    max-width: 80%;
  `};

  ${lessThan('small')`
    max-width: 90%;
  `};

  a {
    color: ${({ theme }) => theme.colorRed};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const caretCommonStyle = css`
  position: absolute;
  display: block;
  content: '';
  bottom: 0;
  width: 0;
  height: 0;

  border-top: 8px solid transparent;
`;

const leftBubbleContainerStyle = css`
  border-bottom-left-radius: 0px;
`;

const rightBubbleContainerStyle = css`
  margin-right: 32px;
  border-bottom-right-radius: 0px;

  ${lessThan('medium')`
    margin-right: 14px;
  `};
`;

const leftBubbleCaretStyle = css`
  &:before {
    ${caretCommonStyle}
    left: -32px;
    border-left: 16px solid transparent;
    border-right: ${(props) => `16px solid ${getBackgroundColor(props)}`};
  }
`;

const rightBubbleCaretStyle = css`
  &:before {
    ${caretCommonStyle}
    right: -32px;
    border-right: 16px solid transparent;
    border-left: ${(props) => `16px solid ${getBackgroundColor(props)}`};
  }
`;

export const BubbleContainer = styled.div`
  ${BubbleContainerCommonStyle};

  ${(props) =>
    props.isLoading &&
    css`
      padding: 12px 18px;
    `};

  ${(props) =>
    isLeft(props.position)
      ? leftBubbleContainerStyle
      : rightBubbleContainerStyle};
  ${(props) =>
    isLast(props)
      ? isLeft(props.position)
        ? leftBubbleCaretStyle
        : rightBubbleCaretStyle
      : ``};
`;
