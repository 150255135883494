import styled from 'styled-components';

const Wrapper = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;

  font-family: ${({ theme }) => theme.fontFamilyBold};
  padding: 8px 12px;
  color: ${({ theme }) => theme.colorWhite};
  background-color: ${({ theme }) => theme.colorRed};
`;

export default function VersionNumber() {
  if (!process.env.REACT_APP_VERSION) {
    return <></>;
  }
  return <Wrapper>v{process.env.REACT_APP_VERSION}</Wrapper>;
}
