import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { selectPersonalData, submitMessage } from 'features/Board/board.slice';
import { RatingContainer, StarIcon, StarIconContainer } from './Rating.style';

const maxRating = 5;

function Rating({ message }) {
  const dispatch = useDispatch();
  const personalData = useSelector(selectPersonalData);

  const onRatingClick = useCallback(
    (updatedRating) => {
      dispatch(submitMessage(message?.id, updatedRating));
    },
    [dispatch, message],
  );

  const rating = personalData?.feedbackRating;

  return (
    <RatingContainer>
      {Array(maxRating)
        .fill(1)
        .map((_, index) => (
          <StarIconContainer key={index}>
            <StarIcon
              selected={rating === 0 ? false : index >= maxRating - rating}
              onClick={() => onRatingClick(maxRating - index)}
            />
          </StarIconContainer>
        ))}
    </RatingContainer>
  );
}

export default Rating;
