import styled, { keyframes } from 'styled-components';

const animation = keyframes`
    0% {
      opacity: 0.9;
      transform: scale(0.5);
    }
    100% {
      opacity: 0.1;
      transform: scale(1);
    }
    `;
const dimension = '8px';

export const Loader = styled.div`
  text-align: center;
`;

export const Dot = styled.div`
  display: inline-block;
  vertical-align: middle;
  width: ${dimension};
  height: ${dimension};
  background: ${(props) => props.theme.colorBackground01};
  border-radius: 100%;
  animation: ${animation} 0.8s infinite alternate;
  transform: ${(props) => `scale(${props.scale || 0.5})`};
  margin-right: 3px;

  &:nth-of-type(2) {
    animation-delay: 0.2s;
  }
  &:nth-of-type(3) {
    animation-delay: 0.6s;
  }
`;
