import PropTypes from 'prop-types';

import { ReactComponent as IconVibeInactive } from 'assets/icons/vibe-inactive.svg';
import { ReactComponent as IconVibeActive } from 'assets/icons/vibe-active.svg';

import { MessageContent } from './components';
import { getPosition, isLeft } from './helpers';
import {
  ProfilePictureContainer,
  MessageContainer,
  BubbleContainer,
} from './Message.style';

function VibeProfilePicture({ isActive }) {
  return (
    <ProfilePictureContainer>
      {isActive && <IconVibeActive />}
      {!isActive && <IconVibeInactive />}
    </ProfilePictureContainer>
  );
}

function Message({ message, isActive, isCurrent }) {
  const position = getPosition(message?.type);
  const isLeftBoolean = isLeft(position);

  let framerInitial = { opacity: 0, x: -100 };
  let framerAnimate = { opacity: 1, x: 0 };
  let framerExit = { opacity: 0 };

  if (!isLeftBoolean) {
    framerInitial = { opacity: 0, x: 200 };
    framerAnimate = { opacity: 1, x: 0 };
  }

  return (
    <MessageContainer
      key={message.id}
      id={message.id}
      position={position}
      initial={framerInitial}
      animate={framerAnimate}
      exit={framerExit}
    >
      {isLeftBoolean && <VibeProfilePicture isActive={isActive} />}
      <BubbleContainer className={isActive && 'is-active'} position={position}>
        <MessageContent
          message={message}
          position={position}
          isCurrent={isCurrent}
        />
      </BubbleContainer>
    </MessageContainer>
  );
}

Message.propTypes = {
  message: PropTypes.object,
  isActive: PropTypes.bool,
  isCurrent: PropTypes.bool,
};

export default Message;
