import { useCallback, useLayoutEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import NumberFormat from 'react-number-format';

import thousandSeparator from 'utils/thousandSeparator';
import { SpriteIcon } from 'components';

import { useTranslation } from 'react-i18next';

import {
  selectInputSettings,
  submitMessage,
  changeInputSettingsValue,
  userInteraction,
} from '../board.slice';
import {
  Button,
  ChatBoxContainer,
  ErrorText,
  Input,
  TextArea,
} from './ChatBox.style';

function ChatBox() {
  const dispatch = useDispatch();
  const inputRef = useRef(null);

  const { t } = useTranslation();

  const inputSettings = useSelector(selectInputSettings);

  // submit message on enter
  const onMessageSubmit = useCallback(() => {
    const messageId =
      inputSettings?.editingMessageId || inputSettings?.messageId;
    dispatch(submitMessage(messageId, inputSettings.value));
  }, [dispatch, inputSettings]);

  // submit message on enter
  const onKeyPress = useCallback(
    (event) => {
      if (event.key === 'Enter' && event.shiftKey) {
        return;
      }
      if (event.key === 'Enter') {
        onMessageSubmit();
      }
    },
    [onMessageSubmit],
  );

  const parsedPlaceholder = inputSettings?.isActive
    ? t(inputSettings.placeholder)
    : '';

  // check which input should be rendered
  let inputContent = (
    <Input
      type={inputSettings.inputType === 'EMAIL' ? 'email' : 'text'}
      placeholder={parsedPlaceholder}
      disabled={!inputSettings.isActive}
      value={inputSettings.value}
      onChange={(event) =>
        dispatch(changeInputSettingsValue(event.target.value))
      }
      onKeyPress={onKeyPress}
      ref={inputRef}
    />
  );

  if (inputSettings.inputType === 'CURRENCY') {
    inputContent = (
      <NumberFormat
        prefix="CHF "
        pattern="[0-9]*"
        thousandSeparator={thousandSeparator(inputSettings.value)}
        allowNegative={false}
        customInput={Input}
        placeholder={parsedPlaceholder}
        disabled={!inputSettings.isActive}
        value={inputSettings.value}
        onKeyPress={onKeyPress}
        onValueChange={({ value }) => dispatch(changeInputSettingsValue(value))}
        getInputRef={inputRef}
      />
    );
  }

  if (inputSettings.inputType === 'DATE') {
    inputContent = (
      <NumberFormat
        format="##.##.####"
        mask="_"
        pattern="[0-9]*"
        allowNegative={false}
        customInput={Input}
        placeholder={parsedPlaceholder}
        disabled={!inputSettings.isActive}
        value={inputSettings.value}
        onKeyPress={onKeyPress}
        onValueChange={({ value }) => dispatch(changeInputSettingsValue(value))}
        getInputRef={inputRef}
      />
    );
  }

  if (inputSettings.inputType === 'SHORT_DATE') {
    inputContent = (
      <NumberFormat
        format="##.####"
        mask="_"
        pattern="[0-9]*"
        allowNegative={false}
        customInput={Input}
        placeholder={parsedPlaceholder}
        disabled={!inputSettings.isActive}
        value={inputSettings.value}
        onKeyPress={onKeyPress}
        onValueChange={({ value }) => dispatch(changeInputSettingsValue(value))}
        getInputRef={inputRef}
      />
    );
  }

  if (inputSettings.inputType === 'PHONE') {
    inputContent = (
      <Input
        name="phone-input"
        autoComplete="off"
        type="tel"
        placeholder={parsedPlaceholder}
        disabled={!inputSettings.isActive}
        value={inputSettings.value}
        onChange={(event) =>
          dispatch(changeInputSettingsValue(event.target.value))
        }
        onKeyPress={onKeyPress}
        ref={inputRef}
      />
    );
  }

  if (inputSettings.inputType === 'ZIP') {
    inputContent = (
      <Input
        name="search-input"
        autoComplete="off"
        type="text"
        pattern="[0-9]*"
        placeholder={parsedPlaceholder}
        disabled={!inputSettings.isActive}
        value={inputSettings.value}
        onChange={(event) =>
          dispatch(changeInputSettingsValue(event.target.value))
        }
        onKeyPress={onKeyPress}
        ref={inputRef}
      />
    );
  }

  if (inputSettings.inputType === 'TEXTAREA')
    inputContent = (
      <TextArea
        type={'text'}
        placeholder={parsedPlaceholder}
        disabled={!inputSettings.isActive}
        value={inputSettings.value}
        onChange={(event) =>
          dispatch(changeInputSettingsValue(event.target.value))
        }
        onKeyPress={onKeyPress}
        ref={inputRef}
      />
    );

  useLayoutEffect(() => {
    if (!inputSettings.isActive) return;
    inputRef?.current?.focus();
  }, [inputSettings, inputRef]);

  const renderErrorStyle = inputSettings.isActive && inputSettings.hasError;
  return (
    <>
      <ChatBoxContainer
        className="vibe-chat-box"
        hasError={renderErrorStyle}
        onClick={() => dispatch(userInteraction())}
      >
        {inputContent}
        <Button onClick={onMessageSubmit} disabled={!inputSettings.isActive}>
          <SpriteIcon name="arrow-right-20" />
        </Button>
      </ChatBoxContainer>
      {renderErrorStyle && <ErrorText>{inputSettings.errorMessage}</ErrorText>}
    </>
  );
}

ChatBox.propTypes = {};

export default ChatBox;
