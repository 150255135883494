import i18n from 'i18next';

import config from 'global-config';
import evaTracking from 'utils/evaTracking';

// submit consultation request
export async function submitConsultationRequest(state) {
  const boardState = state.board;
  const offerState = state.offer;

  const requestData = {
    ...boardState?.personalData,
    disclaimerAccepted: true,
    shareDataAccepted: boardState?.personalData?.shareDataAccepted,
    language: i18n.language,
    zipCode: boardState?.calculationData?.postalCode,
    data: {
      interestRates: {
        labels: {
          type: 'Art der Hypothek',
          duration: 'Dauer',
          interestRate: 'Zins ab*',
          costs: 'Kosten/Monat ab*',
        },
        offers: offerState.interestRates,
      },
      requestData: {
        ...boardState.calculationData,
        isRedemptionMode: true,
        salesChannel: 'SL',
        language: i18n.language,
      },
    },
  };

  evaTracking.trackCustom('formSuccess', 'formular-lead-private', {
    submitType: 'ajax',
  });
  evaTracking.appEvent('lead_private_submit');

  const requestURL = `${config.backend.apiUrl}/v1/vibe/requestconsultation`;
  const apiResponse = await fetch(requestURL, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(requestData),
  });

  const data = await apiResponse.json();
  if (apiResponse.ok) {
    return data || [];
  }
  throw new Error(apiResponse.status);
}

// submit reminder request
export async function submitReminderRequest(state, eMail) {
  const boardState = state.board;

  const requestData = {
    ...boardState?.personalData,
    eMail,
    maturityDate: boardState?.calculationData?.dueDate,
    disclaimerAccepted: true,
    language: i18n.language,
  };

  evaTracking.trackCustom('formSuccess', 'formular-soft-lead-private', {
    submitType: 'ajax',
  });
  evaTracking.appEvent('soft_lead_private_submit');

  const requestURL = `${config.backend.apiUrl}/v1/vibe/reminder`;
  const apiResponse = await fetch(requestURL, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(requestData),
  });

  const data = await apiResponse.json();
  if (apiResponse.ok) {
    return data || [];
  }
  throw new Error(apiResponse.status);
}

// submit feedback
export async function submitFeedback(state, feedback) {
  const requestData = {
    rating: state.board?.personalData?.feedbackRating,
    feedback,
  };

  evaTracking.appEvent('rating_submit', requestData?.rating);

  const requestURL = `${config.backend.apiUrl}/v1/vibe/feedback`;
  await fetch(requestURL, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(requestData),
  });
}
