import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import i18n from 'i18next';

import { scrollToBottomOfMessageHistory } from 'utils/scroll';
import evaTracking from 'utils/evaTracking';
import filterMortgageDurations from 'utils/filterMortgageDurations';

import {
  selectMessages,
  editMessage,
  setMessageIsLoading,
  selectCalculationData,
} from 'features/Board/board.slice';

import {
  downloadDocument,
  fetchDefaultOffers,
  fetchRedemptionOffers,
} from './Offer.api';
import { findFixedOffer, findSaronOffer } from './Offer.helpers';

const initialState = {
  // widget state
  isLoading: true,
  hasError: false,
  interestRates: [],
  knockout: false,
  knockoutThresholdIncome: false,
  knockoutThresholdMarketValue: false,
  saronRate: {},
  selectedFixedRate: {},

  // misc
  isOfferRendered: false,
  isOfferVisible: false,
  isOfferCollpased: false,
  isOfferAccepted: false,
};

function getSalesChannel() {
  try {
    // check if we are on swisslife-select.ch
    if (window?.location?.origin?.includes('swisslife-select.ch')) {
      return 'SLS';
    }

    // by default return SL
    return 'SL';
  } catch {
    return 'SL';
  }
}

export const fetchDefaultOffer = createAsyncThunk(
  'offer/fetchDefaultOffer',
  async (amount) => {
    const response = await fetchDefaultOffers(amount);
    return response;
  },
);

export const fetchRedemptionOffer = createAsyncThunk(
  'offer/fetchRedemptionOffer',
  async (requestData, { dispatch, getState }) => {
    const response = await fetchRedemptionOffers({
      ...requestData,
      payoutDate: dayjs(requestData?.dueDate).format('MM.YYYY'),
      language: i18n.language,
      salesChannel: getSalesChannel(),
    });

    // if knockout is "Affordability", we set the "grossYearlyIncome" to be in edit mode
    if (response?.knockout === 'Affordability') {
      const messages = selectMessages(getState());
      const grossYearlyIncomeMessage = messages?.find(
        (messageItr) => messageItr?.params?.dataKey === 'grossYearlyIncome',
      );

      if (grossYearlyIncomeMessage) {
        dispatch(
          editMessage(
            grossYearlyIncomeMessage.id,
            response.knockoutThresholdIncome,
          ),
        );
      }
    }

    // if knockout is "NetLtv", we set the "marketValue" to be in edit mode
    if (response?.knockout === 'NetLtv') {
      const messages = selectMessages(getState());
      const marketValueMessage = messages?.find(
        (messageItr) => messageItr?.params?.dataKey === 'marketValue',
      );

      if (marketValueMessage) {
        dispatch(
          editMessage(
            marketValueMessage.id,
            response.knockoutThresholdMarketValue,
          ),
        );
      }
    }

    return response;
  },
);

export const fetchHypoCheckDocument = createAsyncThunk(
  'offer/fetchHypoCheckDocument',
  async (messageId, { dispatch, getState }) => {
    dispatch(setMessageIsLoading({ messageId, isLoading: true }));

    try {
      const requestData = selectCalculationData(getState());
      const interestRates = selectInterestRates(getState());
      const selectedFixedRate = selectSelectedFixedRate(getState());

      const fixedMortgageDurations = filterMortgageDurations(
        interestRates,
        selectedFixedRate,
      );

      await downloadDocument({
        ...requestData,
        salesChannel: getSalesChannel(),
        payoutDate: dayjs(requestData?.dueDate).format('MM.YYYY'),
        fixedMortgageDurations,
        language: i18n.language,
      });
      dispatch(setMessageIsLoading({ messageId, isLoading: false }));
    } catch (err) {
      console.log(err);
      dispatch(setMessageIsLoading({ messageId, isLoading: false }));
    }
  },
);

function onOfferFulfilled(state, action) {
  const {
    interestRatesOverview = [],
    knockout = false,
    knockoutThresholdIncome,
    knockoutThresholdMarketValue,
    fetchedAt,
  } = action?.payload;

  const filteredRates = interestRatesOverview?.filter(
    (rateItr) => rateItr?.productType !== 'Fixed' || rateItr?.duration > 1,
  );

  state.isLoading = false;
  state.fetchedAt = fetchedAt;
  state.hasError = false;
  state.interestRates = filteredRates;
  state.saronRate = findSaronOffer(interestRatesOverview);
  state.selectedFixedRate = filteredRates?.[0];
  state.knockout = knockout || false;
  state.knockoutThresholdIncome = knockoutThresholdIncome || false;
  state.knockoutThresholdMarketValue = knockoutThresholdMarketValue || false;

  // scroll to bottom
  setTimeout(() => {
    scrollToBottomOfMessageHistory();
  }, 200);
}

export const offerSlice = createSlice({
  name: 'offer',
  initialState,
  reducers: {
    changeSelectedFixedRate: (state, action) => {
      const fixedRateOffer = findFixedOffer(
        state.interestRates,
        action.payload,
      );
      if (!fixedRateOffer) {
        return;
      }

      evaTracking.appEvent('offer_selectrate');
      state.selectedFixedRate = fixedRateOffer;
    },
    changeIsOfferRendered: (state, action) => {
      state.isOfferRendered = action.payload;
    },
    changeIsOfferVisible: (state, action) => {
      state.isOfferVisible = action.payload;
    },
    changeIsOfferCollapsed: (state, action) => {
      state.isOfferCollpased = action.payload;
    },
    changeIsOfferAccepted: (state, action) => {
      state.isOfferAccepted = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDefaultOffer.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchDefaultOffer.rejected, (state) => {
        state.isLoading = false;
        state.hasError = true;
      })
      .addCase(fetchDefaultOffer.fulfilled, (state, action) =>
        onOfferFulfilled(state, action),
      )
      .addCase(fetchRedemptionOffer.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchRedemptionOffer.rejected, (state) => {
        state.isLoading = false;
        state.hasError = true;
      })
      .addCase(fetchRedemptionOffer.fulfilled, (state, action) =>
        onOfferFulfilled(state, action),
      );
  },
});

export default offerSlice.reducer;

// Selectors
export const selectIsLoading = (state) => state.offer.isLoading;
export const selectFetchedAt = (state) => state.offer.fetchedAt;
export const selectHasError = (state) => state.offer.hasError;
export const selectKnockout = (state) => state.offer.knockout || false;
export const selectInterestRates = (state) => state.offer.interestRates || [];
export const selectSaronRate = (state) => state.offer.saronRate || {};
export const selectSelectedFixedRate = (state) =>
  state.offer.selectedFixedRate || {};
export const selectIsOfferRendered = (state) => state.offer.isOfferRendered;
export const selectIsOfferVisible = (state) => state.offer.isOfferVisible;
export const selectIsOfferCollapsed = (state) => state.offer.isOfferCollpased;
export const selectIsOfferAccepted = (state) => state.offer.isOfferAccepted;

export const selectIsStickyOfferVisible = (state) => {
  const isOfferRendered = selectIsOfferRendered(state);
  const isOfferVisible = selectIsOfferVisible(state);
  const isOfferAccepted = selectIsOfferAccepted(state);
  return isOfferRendered && isOfferAccepted && !isOfferVisible;
};

export const selectCombinedKnockout = (state) => {
  if (!state.offer.knockout) {
    return false;
  }

  return {
    knockout: state.offer.knockout,
    knockoutThresholdIncome: state.offer.knockoutThresholdIncome,
    knockoutThresholdMarketValue: state.offer.knockoutThresholdMarketValue,
  };
};

// Actions
export const {
  changeIsOfferRendered,
  changeStickyOfferVisible,
  changeSelectedFixedRate,
  changeIsOfferVisible,
  changeIsOfferAccepted,
  changeIsOfferCollapsed,
} = offerSlice.actions;
