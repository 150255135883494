import styled from 'styled-components';
import { motion } from 'framer-motion';

export const StickContainer = styled(motion.div)`
  position: absolute;
  top: 0;
  z-index: 99;
  width: 100%;
  padding: 8px;

  & > div {
    box-shadow: 0px 20px 14px 0px #ffffff90;
    background: linear-gradient(180deg, white, white, white, #ffffff94);
  }
`;
