import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import ReactMarkdown from 'react-markdown';

import { Bubble, BubbleWithLoader } from 'components';

import {
  selectCurrentMessage,
  selectPersonalData,
} from 'features/Board/board.slice';
import useTimer from 'utils/useTimer';

import { useTranslation } from 'react-i18next';

export const loadingTime = 1000;

function SimpleMessage({ message, position }) {
  const { t } = useTranslation();

  const personalData = useSelector(selectPersonalData);
  const currentMessage = useSelector(selectCurrentMessage);

  const { bubbles } = message?.params || {};
  const bubbleLength = useMemo(() => bubbles.length, [bubbles]);

  // disable loader for rerenders (used on the modal mode of the message history)
  let timerStart = bubbleLength;
  if (currentMessage?.id === message?.id) {
    timerStart = 0;
  }
  const currentBubbleId = useTimer(loadingTime, timerStart, bubbleLength);

  const uiBubbles = useMemo(() => {
    const bubbleComponents = Array(currentBubbleId)
      .fill('')
      .map((_, idx) => {
        return (
          <Bubble
            key={idx}
            position={position}
            isLast={idx === bubbles.length - 1}
            layoutId={bubbles[idx].id}
          >
            <ReactMarkdown linkTarget="_blank">
              {t(bubbles[idx], { fullName: personalData.fullName })}
            </ReactMarkdown>
          </Bubble>
        );
      });
    return bubbleComponents;
  }, [t, bubbles, currentBubbleId, position, personalData]);

  const isLoaderVisible = useMemo(() => {
    return currentBubbleId <= bubbleLength - 1;
  }, [currentBubbleId, bubbleLength]);

  return (
    <>
      {uiBubbles}
      {isLoaderVisible && <BubbleWithLoader position={'left'} />}
    </>
  );
}

SimpleMessage.propTypes = {
  message: PropTypes.object.isRequired,
  position: PropTypes.oneOf(['left', 'right']),
};

export default SimpleMessage;
