export function findSaronOffer(interestRates) {
  const saronOffer = interestRates?.find(
    (offerItr) => offerItr?.productType === 'Saron',
  );
  return saronOffer || {};
}

export function findFixedOffer(interestRates, mortgageTerm) {
  const fixedInterestRates = interestRates?.find(
    ({ productType, duration }) =>
      productType === 'Fixed' && duration === mortgageTerm,
  );
  return fixedInterestRates || {};
}

export function findSliderSettings(interestRates) {
  const fixedInterestRates = interestRates?.filter(
    (offerItr) => offerItr?.productType === 'Fixed',
  );
  const offerDurations = fixedInterestRates?.map(
    (offerItr) => offerItr?.duration,
  );

  const min = Math.min(...offerDurations);
  const max = Math.max(...offerDurations);
  return {
    min,
    max,
    marks: {
      [min]: min,
      [max]: max,
    },
  };
}

export function displayInterestRate(interestRate) {
  return `${interestRate?.interestRateFrom?.toFixed(2)}%` || '0%';
}
