import PropTypes from 'prop-types';

import { Bubble, TypingLoader } from 'components';

const BubbleWithLoader = ({ position }) => {
  return (
    <Bubble position={position} isLast={true}>
      <TypingLoader />
    </Bubble>
  );
};

BubbleWithLoader.propTypes = {
  position: PropTypes.oneOf(['left', 'right']),
};

export default BubbleWithLoader;
