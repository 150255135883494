import styled from 'styled-components';

export const InteractionContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const SpriteIconContainer = styled.div`
  display: flex;
  margin-left: auto;
  cursor: pointer;
`;
