/* eslint-disable no-console, max-classes-per-file */

class FakeDataLayerEvent {
  properties = {};

  addProperty(key, value) {
    this.properties[key] = value;
  }
}

// EvaTracking
class EvaTracking {
  constructor() {
    this.appName = 'eva';
    this.sentFirstInteractionEvent = false;

    if (
      !window?.analytics?.dataLayer &&
      !window.location.host.includes('swisslife.ch')
    ) {
      this.addFakeDataLayer();
    }
  }

  addFakeDataLayer() {
    if (!window?.analytics) {
      window.analytics = {
        dataLayer: {},
      };
    }

    window.analytics.dataLayer.createNewEvent = () => new FakeDataLayerEvent();

    window.analytics.dataLayer.addEventAndExecute = (event) =>
      console.info('[evaTrackint]: addEventAndExecute', event?.properties);
  }

  // helper functions to generate appEventNames
  capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  setAppName(appName, prefix) {
    this.appName = appName;
    this.prefix = prefix;
  }

  track(event, label = '', noPrefix) {
    try {
      let appEvent = event;
      if (!noPrefix) {
        appEvent = `${this.prefix}${this.capitalize(event)}`;
      }

      const dataLayerEvent = window.analytics.dataLayer.createNewEvent();
      dataLayerEvent.addProperty('analyticsEvent', 'appEvent');
      dataLayerEvent.addProperty('appName', this.appName);
      dataLayerEvent.addProperty('appEventName', appEvent);
      dataLayerEvent.addProperty('label', label);
      window.analytics.dataLayer.addEventAndExecute(dataLayerEvent);
    } catch (e) {
      // console.log('e', e);
    }
  }

  appEvent(appEventName, appEventValue = '', appName = this.appName) {
    try {
      const dataLayerEvent = window.analytics.dataLayer.createNewEvent();
      dataLayerEvent.addProperty('analyticsEvent', 'appEvent');
      dataLayerEvent.addProperty('appName', appName);
      dataLayerEvent.addProperty('appEventName', `vibe_${appEventName}`);
      if (appEventValue) {
        dataLayerEvent.addProperty('appEventValue', appEventValue);
      }
      window.analytics.dataLayer.addEventAndExecute(dataLayerEvent);
    } catch (e) {
      // console.log('e', e);
    }

    try {
      /* global _paq */
      _paq.push([
        'trackEvent',
        'appEvent',
        appName,
        appEventName,
        appEventValue,
      ]);
    } catch (e) {
      // console.log('e', e);
    }
  }

  trackCustom(analyticsEvent, analyticsId, customProperties = {}) {
    try {
      const event = window.analytics.dataLayer.createNewEvent();
      event.addProperty('analyticsEvent', analyticsEvent);
      event.addProperty('analyticsId', analyticsId);
      Object.keys(customProperties).forEach((propertyKey) => {
        event.addProperty(propertyKey, customProperties[propertyKey]);
      });
      window.analytics.dataLayer.addEventAndExecute(event);
    } catch (e) {
      // console.log('e', e);
    }
  }

  trackDownload(fileId) {
    try {
      // manually check if the user allowed the usage of tracking and marketing cookies
      const activeGroups = window.OptanonActiveGroups || '';
      if (activeGroups.indexOf('C0002') > -1) {
        window.s.tl(true, 'd', fileId);
      }
    } catch (err) {}
  }

  trackMessage(message, isSubmit, appEventValue = '') {
    if (!message?.appEvent && !message?.appEventSubmit) {
      return;
    }

    // track regular events
    if (!isSubmit && message?.appEvent) {
      this.appEvent(message.appEvent, appEventValue);
    }

    if (!isSubmit) {
      return;
    }

    if (!this.sentFirstInteractionEvent) {
      this.appEvent('interaction');
      this.sentFirstInteractionEvent = true;
    }

    // track submit
    let appEventName = `${message.appEvent}_continue`;
    if (message?.appEventSubmit) {
      appEventName = message?.appEventSubmit;
    }
    this.appEvent(appEventName, appEventValue);
  }
}
export default new EvaTracking();
