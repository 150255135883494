import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import theme from 'utils/theme';

import { Spinner, SpriteIcon, Typography } from 'components';
import { submitMessage } from 'features/Board/board.slice';

import { useTranslation } from 'react-i18next';

import {
  CheckMarkContainer,
  IconContainer,
  RadioContainer,
  RadioItem,
} from './Radio.style';

import { ReactComponent as IconVibeBuilding } from 'assets/icons/building.svg';

function renderIcon(option) {
  if (option?.spriteIconName) {
    return <SpriteIcon name={option?.spriteIconName} />;
  }

  if (option?.vibeIconName) {
    switch (option?.vibeIconName) {
      case 'building':
        return <IconVibeBuilding />;
      default:
        <></>;
    }
  }

  return <></>;
}

function Radio({ message }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { id, value, isLoading } = message;
  const { title, options, isBranchSwitch } = message?.params;

  const isDisabled = isLoading || (value && isBranchSwitch);

  const radioItems = options.map((optionItr) => {
    const isSelected = optionItr?.value === value;

    const onClick = () => {
      if (isSelected) {
        return;
      }
      dispatch(submitMessage(id, optionItr?.value));
    };

    return (
      <RadioItem
        key={optionItr?.value}
        isSelected={isSelected}
        onClick={() => onClick()}
        disabled={isDisabled}
      >
        {optionItr?.showLoader && isLoading ? (
          <Spinner color={!isSelected ? theme.colorRed : theme.colorWhite} />
        ) : (
          optionItr?.hasIcon && (
            <IconContainer isSelected={isSelected}>
              {renderIcon(optionItr)}
              {isSelected && (
                <CheckMarkContainer>
                  <SpriteIcon name="checkmark-16" />
                </CheckMarkContainer>
              )}
            </IconContainer>
          )
        )}
        <span>{t(optionItr?.label)}</span>
      </RadioItem>
    );
  });

  return (
    <>
      <Typography>{t(title)}</Typography>
      <RadioContainer>{radioItems}</RadioContainer>
    </>
  );
}

Radio.propTypes = {
  isCurrent: PropTypes.bool,
  message: PropTypes.object.isRequired,
};

export default React.memo(Radio);
