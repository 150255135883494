import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import config from 'global-config';

import deTranslations from './assets/translations/de.json';
import enTranslations from './assets/translations/en.json';
import frTranslations from './assets/translations/fr.json';
import itTranslations from './assets/translations/it.json';

i18n.use(initReactI18next).init({
  fallbackLng: config.defaultLanguage,
  resources: {
    de: {
      translation: deTranslations,
    },
    en: {
      translation: enTranslations,
    },
    fr: {
      translation: frTranslations,
    },
    it: {
      translation: itTranslations,
    },
  },
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
});

export default i18n;
