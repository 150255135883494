import React from 'react';
import dayjs from 'dayjs';
import parsePhoneNumber from 'libphonenumber-js';
import NumberFormat from 'react-number-format';
import { Trans } from 'react-i18next';

import thousandSeparator from 'utils/thousandSeparator';

function validateDate(value, message) {
  const params = message?.params || {};
  const formatStr = params?.inputType === 'SHORT_DATE' ? 'MMYYYY' : 'DDMMYYYY';

  const parsedDate = dayjs(value, formatStr, true).endOf('day');
  if (!parsedDate?.isValid()) {
    return [value, false];
  }

  // check if the date has more constraints
  if (params?.validation?.min) {
    const minDate = dayjs(params?.validation?.min).endOf('day');
    if (parsedDate.isBefore(minDate)) {
      return [value, false];
    }
  }
  if (params?.validation?.max) {
    const maxDate = dayjs(params?.validation?.max).endOf('day');
    if (parsedDate.isAfter(maxDate)) {
      return [value, false];
    }
  }

  const maxYear = new Date().getFullYear() + 10;
  if (parsedDate.year() > maxYear) {
    return [value, false];
  }
  return [value, true];
}

function validateName(value) {
  return [value, value.length < 150 && value.length > 4];
}

function validatePhone(value) {
  const phoneNumber = parsePhoneNumber(value, 'CH');
  if (phoneNumber?.isValid()) {
    return [phoneNumber.formatInternational(), phoneNumber?.isValid()];
  }
  return [value, false];
}

function validateEMail(value) {
  return [value, /(.+)@(.+){2,}\.(.+){2,}/.test(value)];
}

function validateNumber(value, message) {
  const params = message?.params || {};

  let intValue = value;
  if (typeof intValue === 'string') {
    intValue = parseInt(intValue, 10);
  }

  if (Number.isNaN(intValue)) {
    return [value, false];
  }

  if (params?.validation?.min && intValue < params?.validation?.min) {
    return [intValue, false];
  }
  if (params?.validation?.max && intValue > params?.validation?.min) {
    return [intValue, false];
  }

  return [intValue, true];
}

function validateZipCode(value, message) {
  return [value, value?.length === 4];
}

// custom validators

function customValidatorMarketValue(value, message, state) {
  let intValue = value;
  if (typeof intValue === 'string') {
    intValue = parseInt(intValue, 10);
  }

  if (Number.isNaN(intValue)) {
    return [value, false, 'invalidNumber'];
  }

  const minValueByMortgageValue =
    (state?.calculationData?.mortgageValue * 100) / 80;
  const minValue = Math.max(minValueByMortgageValue, 100_000);
  return [
    intValue,
    intValue >= minValue,
    <Trans
      i18nKey="vibe.error.loadToValueRatio"
      components={{
        FormattedNumber: (
          <NumberFormat
            prefix=" CHF "
            suffix=""
            pattern="[0-9]*"
            thousandSeparator={thousandSeparator(minValue)}
            value={minValue}
            displayType="text"
          />
        ),
      }}
    />,
  ];
}

export default function validateAndFormat(value, message, state) {
  const params = message?.params || {};

  // check if the message has special validation needs
  const customValidatorId = params?.validation?.customValidatorId;
  if (customValidatorId) {
    switch (customValidatorId) {
      case 'marketValueValidator':
        return customValidatorMarketValue(value, message, state);
      default:
        console.log('unknown validator', customValidatorId);
    }
  }

  if (!params?.inputType) {
    return [value, true];
  }

  // if no customValidatorId is present or, it is an unknown one, we validate by type
  switch (params?.inputType) {
    case 'DATE':
      return validateDate(value, message);
    case 'FULL_NAME':
      return validateName(value, message);
    case 'SHORT_DATE':
      return validateDate(value, message);
    case 'PHONE':
      return validatePhone(value, message);
    case 'EMAIL':
      return validateEMail(value, message);
    case 'CURRENCY':
      return validateNumber(value, message);
    case 'ZIP':
      return validateZipCode(value, message);
    case 'BOOLEAN':
      return [value, true];
    case 'CALENDAR':
      return [value, true];
    default:
      return [value, value?.length > 0];
  }
}
