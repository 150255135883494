import dayjs from 'dayjs';

import { getMinDate } from 'utils/consultationDate';

const messages = {
  id: '72aff361-b4b1-4d32-812f-5664ba534043',
  entrypoint: '2977b1ab-3a75-4c04-ba4d-d6d0bb213625',
  branches: [
    // Flow 1: Select the dueDate / mode
    {
      id: '2977b1ab-3a75-4c04-ba4d-d6d0bb213625',
      messages: [
        {
          id: 'd9b347f3-d8db-46ec-b878-21bbd0a632bc',
          type: 'MESSAGE',
          params: {
            bubbles: ['vibe.flow.welcome.intro'],
          },
        },
        {
          id: 'fe17207d-a800-4d19-8a67-a8c7efc96b12',
          type: 'MESSAGE',
          params: {
            bubbles: ['vibe.flow.welcome.startNow'],
          },
        },
        {
          id: 'ed2d9faf-c487-4abe-81f0-8334c7cded84',
          type: 'INTERACTION',
          params: {
            interactionType: 'SELECT',
            title: 'vibe.flow.welcome.questionMortgageTranchesCount',
            options: [
              {
                label: 'vibe.flow.welcome.answerOneMortgageTranche',
                value: '325f80c3-4f48-4844-ae58-b68a622a9dfe',
              },
              {
                label: 'vibe.flow.welcome.answerSeveralMortgageTranches',
                value: 'f293312c-afaf-4534-b816-d06a3b445f76',
              },
            ],
            isBranchSwitch: true,
          },
          appEvent: 'welcome_tranchescount',
        },
      ],
    },

    // Flow 1.1: Route user based on the dueDate
    {
      id: '325f80c3-4f48-4844-ae58-b68a622a9dfe',
      messages: [
        {
          id: 'e4917518-1200-4dfd-aed1-38260af81a43',
          type: 'MESSAGE',
          params: {
            bubbles: ['vibe.flow.redemption.questionDueDate'],
          },
        },
        {
          id: 'daa0dba9-80c6-472d-9549-231e72e6ed86',
          type: 'INTERACTION',
          params: {
            isBranchSwitch: true,
            branchRouterId: '46b1d4ac-3773-4411-bd22-848b5a637368',
            interactionType: 'MONTH_PICKER',
            placeholder: ['vibe.general.placeholderDueDate'],
            dataKey: 'dueDate',
            inputType: 'SHORT_DATE',
            validation: {
              min: dayjs().toISOString(),
            },
          },
          appEvent: 'redemption_duedate',
        },
      ],
    },

    // Flow 2: (Option 1.0) … läuft in den nächsten 18 Monaten aus
    {
      id: '3c03f678-5d81-406a-9353-e9483bc5aef2',
      messages: [
        {
          id: '119eb720-efba-4162-95bd-655d3a0abb28',
          type: 'MESSAGE',
          params: {
            bubbles: ['vibe.flow.redemption.questionName'],
          },
        },
        {
          id: '3855aa84-a7e2-414f-b5e1-013211096f21',
          type: 'INTERACTION',
          params: {
            interactionType: 'INPUT',
            inputType: 'FULL_NAME',
            placeholder: ['vibe.general.placeholderName'],
            dataKey: 'fullName',
          },
          appEvent: 'redemption_name',
        },
        {
          id: 'df71d0b8-d263-4bb9-8514-d58fa7a216b9',
          type: 'MESSAGE',
          params: {
            bubbles: [
              'vibe.flow.redemption.questionMortgageValueIntro',
              'vibe.flow.redemption.questionMortgageValue',
            ],
          },
        },
        {
          id: '6fefa186-7def-48cd-935c-70202ef7fb35',
          type: 'INTERACTION',
          params: {
            interactionType: 'INPUT',
            placeholder: ['vibe.general.placeholderMortgageValue'],
            dataKey: 'mortgageValue',
            inputType: 'CURRENCY',
            validation: {
              min: 100000,
            },
          },
          appEvent: 'redemption_mortgagevalue',
        },
        {
          id: 'df9a0a26-acaf-4906-ac6e-3cf444d3570f',
          type: 'MESSAGE',
          params: {
            bubbles: ['vibe.flow.redemption.questionPostalCode'],
          },
        },
        {
          id: '25501d8f-611b-46b1-9cbe-029a2f659635',
          type: 'INTERACTION',
          params: {
            interactionType: 'INPUT',
            placeholder: ['vibe.general.placeholderPostalCode'],
            dataKey: 'postalCode',
            inputType: 'ZIP',
          },
          appEvent: 'redemption_postalcode',
        },
        {
          id: 'e0279e32-ed33-4fee-a94f-733dea800c00',
          type: 'MESSAGE',
          params: {
            bubbles: ['vibe.flow.redemption.nearlyCompleted'],
          },
        },
        {
          id: '636339ba-330b-404e-92c8-0fa98e09f755',
          type: 'MESSAGE',
          params: {
            bubbles: [
              'vibe.flow.redemption.questionMarketValue',
              'vibe.flow.redemption.questionMarketValueHint',
            ],
          },
        },
        {
          id: 'e8681748-280c-4fd5-b462-bfecd65515d5',
          type: 'INTERACTION',
          params: {
            interactionType: 'INPUT',
            placeholder: ['vibe.general.placeholderMarketValue'],
            dataKey: 'marketValue',
            inputType: 'CURRENCY',
            validation: {
              customValidatorId: 'marketValueValidator',
            },
          },
          appEvent: 'redemption_marketvalue',
          appEventIncludeValue: true,
        },
        {
          id: '83ce6ba5-e24a-4c1f-aca5-9dc05b070162',
          type: 'MESSAGE',
          params: {
            bubbles: ['vibe.flow.redemption.questionAnnualIncome'],
          },
        },
        {
          id: '0b7209d5-89ce-48bb-a51b-5b5463da3d39',
          type: 'INTERACTION',
          params: {
            interactionType: 'INPUT',
            placeholder: ['vibe.general.placeholderAnnualIncome'],
            dataKey: 'grossYearlyIncome',
            inputType: 'CURRENCY',
            validation: {
              min: 10000,
            },
          },
          appEvent: 'redemption_annualincome',
          appEventIncludeValue: true,
        },
        {
          id: 'dd30bc8e-fa93-4769-aeae-d23ceb1e2c7a',
          type: 'MESSAGE',
          params: {
            bubbles: ['vibe.flow.redemption.offer'],
          },
        },
        {
          id: '245e59c9-e9bf-4df3-8c31-c891e68473cc',
          type: 'OFFER',
          appEvent: 'offer_view',
        },
        {
          id: '740c2d99-08c3-4f24-9dd4-dbdcf24b9a24',
          type: 'MESSAGE',
          params: {
            bubbles: ['vibe.flow.consultation.questionHowToProceed'],
          },
        },
        {
          id: '196147aa-256d-4c06-a6cd-2186c944cf7c',
          type: 'INTERACTION',
          params: {
            interactionType: 'SELECT',
            title: 'vibe.flow.consultation.questionHowToProceed',
            options: [
              {
                label: 'vibe.flow.consultation.optionDiscussOffer',
                value: '09fa4d81-3302-473a-8b98-613d8aabd65f',
                hasIcon: true,
                spriteIconName: 'profile-20',
              },
              {
                label: 'vibe.flow.consultation.optionDownloadSummary',
                value: 'fd8960de-eee2-4a0b-9707-e72c18d182a1',
                hasIcon: true,
                spriteIconName: 'arrow-download-16',
                showLoader: true,
                triggerDownload: true,
              },
            ],
            isBranchSwitch: true,
          },
          appEvent: 'consultation_howtoproceed',
        },
      ],
    },

    // Flow 3: (Option 1.1) Angebot mit Berater/in konkretisieren
    {
      id: '09fa4d81-3302-473a-8b98-613d8aabd65f',
      messages: [
        {
          id: '53e0eadf-16c7-4d0c-833f-593f07f31d53',
          type: 'MESSAGE',
          params: {
            bubbles: ['vibe.flow.consultation.location'],
          },
        },
        {
          id: '5330c59c-ad42-4dc4-ad26-9ca24abbdc72',
          type: 'INTERACTION',
          params: {
            interactionType: 'SELECT',
            options: [
              {
                label: 'vibe.flow.consultation.optionLocationAtHome',
                value: 'onsite',
                hasIcon: true,
                spriteIconName: 'home-20',
              },
              {
                label: 'vibe.flow.consultation.optionLocationAgency',
                value: 'agency',
                hasIcon: true,
                vibeIconName: 'building',
              },
              {
                label: 'vibe.flow.consultation.optionLocationVideo',
                value: 'video',
                hasIcon: true,
                spriteIconName: 'play-button-16',
              },
            ],
            dataKey: 'consultationLocation',
          },
          appEvent: 'consultation_location',
        },
        {
          id: '6f67f0e2-7d00-45d8-a46f-20e1cdd6b5db',
          type: 'MESSAGE',
          params: {
            bubbles: ['vibe.flow.consultation.consultationDate'],
          },
        },
        {
          id: '2713706f-3f2f-492a-8677-25b5a3678a2e',
          type: 'INTERACTION',
          params: {
            interactionType: 'CALENDAR',
            inputType: 'DATE',
            placeholder: ['vibe.general.placeholderConsultationDate'],
            dataKey: 'consultationDate',
            validation: {
              min: getMinDate(),
            },
          },
          value: getMinDate('DDMMYYYY'),
          appEvent: 'consultation_date',
        },
        {
          id: '6077b0fc-2b4f-4ec2-842c-5224e96670f1',
          type: 'MESSAGE',
          params: {
            bubbles: ['vibe.flow.consultation.eMail'],
          },
        },
        {
          id: 'b7ed7729-c689-4ef8-a59e-87604075c453',
          type: 'INTERACTION',
          params: {
            interactionType: 'INPUT',
            inputType: 'EMAIL',
            placeholder: ['vibe.general.placeholderEMail'],
            dataKey: 'consultationEMail',
          },
          appEvent: 'consultation_email',
        },
        {
          id: '00890752-e167-4fe3-b3c5-a2275fdd1694',
          type: 'MESSAGE',
          params: {
            bubbles: ['vibe.flow.consultation.phoneNumber'],
          },
        },
        {
          id: '2bf60e0f-a9cd-41ed-83ce-89afd36b4898',
          type: 'INTERACTION',
          params: {
            interactionType: 'INPUT',
            inputType: 'PHONE',
            placeholder: ['vibe.general.placeholderPhoneNumber'],
            dataKey: 'consultationPhoneNumber',
          },
          appEvent: 'consultation_phonenumber',
        },
        {
          id: 'dd344f90-5dc1-4db6-b9d7-fff1c009bdd9',
          type: 'MESSAGE',
          params: {
            bubbles: ['vibe.flow.consultation.questionShareData'],
          },
        },
        {
          id: '48de12b6-2f1b-4c41-9116-3af47fe93eef',
          type: 'MESSAGE',
          params: {
            bubbles: ['vibe.flow.consultation.questionShareDataMoreInfo'],
          },
        },
        {
          id: '55fe2f71-511d-49a4-bec5-14333de7d234',
          type: 'INTERACTION',
          params: {
            interactionType: 'SELECT',
            inputType: 'BOOLEAN',
            options: [
              {
                label: 'vibe.flow.consultation.optionShareDataAccept',
                value: true,
              },
              {
                label: 'vibe.flow.consultation.optionShareDataReject',
                value: false,
              },
            ],
            dataKey: 'shareDataAccepted',
            dispatchAction: 'board/submitConsultationRequest',
          },
          appEvent: 'consultation_sharedata',
        },
        {
          id: '8448afce-0529-49da-a313-2e2db44e12a2',
          type: 'MESSAGE',
          params: {
            bubbles: ['vibe.flow.consultation.confirmation'],
          },
        },
        {
          id: '656f6f39-1ab4-4154-a8ee-d4367ca38ffd',
          type: 'MESSAGE',
          params: {
            bubbles: ['vibe.flow.goodbye.outro'],
            isBranchSwitch: true,
            branchId: '34db513b-b981-4b8f-993d-4a134fb96d22',
          },
        },
      ],
    },

    // Flow 4: (Option 1.2) Eine Zusammenfassung per E-Mail erhalten
    {
      id: 'fd8960de-eee2-4a0b-9707-e72c18d182a1',
      messages: [
        {
          id: '8e431196-1739-4d5f-a513-8b3095ce5dd9',
          type: 'MESSAGE',
          params: {
            bubbles: ['vibe.flow.consultation.downloadingSummary'],
          },
        },
        {
          id: '2d4cc78f-6538-4a20-9e6a-eb4ea689b74c',
          type: 'MESSAGE',
          params: {
            bubbles: [
              'vibe.flow.goodbye.assistInfo',
              'vibe.flow.consultation.outro',
            ],
            isBranchSwitch: true,
            branchId: '34db513b-b981-4b8f-993d-4a134fb96d22',
          },
        },
      ],
    },

    // Flow 5: (Option 2.0) … läuft länger als 18 Monate
    {
      id: '70868554-400e-4b32-be56-e4eb7afc1fa5',
      messages: [
        {
          id: '2e030fa2-bfe7-4382-9be6-04f03688101b',
          type: 'MESSAGE',
          params: {
            bubbles: [
              'vibe.flow.reminder.intro',
              'vibe.flow.reminder.timingInfo',
            ],
          },
        },
        {
          id: 'abe534b3-e33d-47c6-a5f1-6a58af1daf6f',
          type: 'MESSAGE',
          params: {
            bubbles: ['vibe.flow.reminder.reminderInfo'],
          },
        },
        {
          id: '196147aa-256d-4c06-a6cd-2186c944cf7c',
          type: 'INTERACTION',
          params: {
            interactionType: 'SELECT',
            title: 'vibe.flow.reminder.questionHowToProceed',
            options: [
              {
                label: 'vibe.flow.reminder.optionRemindMe',
                value: '3cba3146-3dc1-4cfb-9844-18bc14c7b2c7',
              },
              {
                label: 'vibe.flow.reminder.optionNoReminder',
                value: 'e32fa848-5d70-421f-9d98-aa33c064332d',
              },
            ],
            isBranchSwitch: true,
          },
          appEvent: 'reminder_info',
        },
      ],
    },

    // Flow 5: (Option 2.1) Reminder
    {
      id: '3cba3146-3dc1-4cfb-9844-18bc14c7b2c7',
      messages: [
        {
          id: '7b3dce78-1ef7-408d-bffa-770f75acca19',
          type: 'MESSAGE',
          params: {
            bubbles: ['vibe.flow.reminder.eMail'],
          },
        },
        {
          id: '7699d24b-352f-4172-b354-cdba195e46df',
          type: 'INTERACTION',
          params: {
            interactionType: 'INPUT',
            inputType: 'EMAIL',
            placeholder: ['vibe.general.placeholderEMail'],
            dataKey: 'eMail',
            dispatchAction: 'board/submitReminderRequest',
          },
          appEvent: 'reminder_email',
        },
        {
          id: '9e14faed-0b47-41c1-815a-a72a3976be0c',
          type: 'MESSAGE',
          params: {
            bubbles: ['vibe.flow.reminder.eMailSent'],
          },
        },
        {
          id: '5072c9b8-d858-410d-83f0-fce7781c3de0',
          type: 'MESSAGE',
          params: {
            bubbles: ['vibe.flow.topConditions'],
          },
        },
        {
          id: 'df48be12-b8cc-490b-b8b3-a56dfbb37832',
          type: 'OFFER',
          params: {
            isTopConditions: true,
          },
          appEvent: 'offer_view_topconditions',
        },
        {
          id: '46da5fc4-3a7f-4807-ac5a-fdbaf5518c62',
          type: 'MESSAGE',
          params: {
            bubbles: ['vibe.flow.goodbye.outro'],
            isBranchSwitch: true,
            branchId: '34db513b-b981-4b8f-993d-4a134fb96d22',
          },
        },
      ],
    },

    // Flow 5: (Option 2.2) Offers
    {
      id: 'e32fa848-5d70-421f-9d98-aa33c064332d',
      messages: [
        {
          id: '7fefffd1-ef16-4c29-9998-648731fba39b',
          type: 'MESSAGE',
          params: {
            bubbles: ['vibe.flow.goodbye.understood'],
          },
        },
        {
          id: '433a49d3-9581-46b6-bb2c-f090d2164c03',
          type: 'MESSAGE',
          params: {
            bubbles: ['vibe.flow.topConditions'],
          },
        },
        {
          id: '688a9806-59fb-452f-a7dd-644e08a7c3bb',
          type: 'OFFER',
          params: {
            isTopConditions: true,
          },
          appEvent: 'offer_view_topconditions',
        },
        {
          id: '04cf6ff6-48b7-4061-9d99-7fc8c659dba0',
          type: 'MESSAGE',
          params: {
            bubbles: ['vibe.flow.goodbye.outro'],
            isBranchSwitch: true,
            branchId: '34db513b-b981-4b8f-993d-4a134fb96d22',
          },
        },
      ],
    },

    // Flow 6: (Option 3.0) ... laufen nicht gleichzeitig aus
    {
      id: 'f293312c-afaf-4534-b816-d06a3b445f76',
      messages: [
        {
          id: '4e4edc7a-c782-4d0a-ac94-f98466eecc94',
          type: 'MESSAGE',
          params: {
            bubbles: ['vibe.flow.severalMortgageTranches.intro'],
          },
        },
        {
          id: '72eaf533-b0ea-4966-be11-e905953b3fea',
          type: 'MESSAGE',
          params: {
            bubbles: ['vibe.flow.severalMortgageTranches.contactInfo'],
          },
        },
        {
          id: '0d477f45-d375-450e-acbf-e51ec04fe364',
          type: 'MESSAGE',
          params: {
            bubbles: ['vibe.flow.goodbye.thankYou', 'vibe.flow.goodbye.outro'],
          },
        },
        {
          id: '40e27a2a-2a7e-4bfc-a093-2393c24ea923',
          type: 'MESSAGE',
          params: {
            bubbles: ['vibe.flow.topConditions'],
            nextMessageDelay: 3000,
          },
        },
        {
          id: 'c77106c3-057f-42eb-b26d-e22e6fea7ba1',
          type: 'OFFER',
          params: {
            isTopConditions: true,
          },
          appEvent: 'offer_view_topconditions',
        },
      ],
    },

    // Flow 7: Rating Start
    {
      id: '34db513b-b981-4b8f-993d-4a134fb96d22',
      messages: [
        {
          id: '4e4edc7a-c782-4d0a-ac94-f98466eecc94',
          type: 'MESSAGE',
          params: {
            bubbles: ['vibe.flow.rating.intro'],
          },
        },
        {
          id: '1bc0e270-09cf-4562-8284-92c5ed651737',
          type: 'RATING',
          params: {
            isBranchSwitch: true,
            branchRouterId: 'ff6650e4-2584-4f85-a3ec-450adf7f8b20',
            dataKey: 'feedbackRating',
          },
          appEvent: 'rating',
        },
      ],
    },

    // 7.1 Rating 1-4 Stars
    {
      id: '6e75273f-52e8-457f-9d15-553234ee2702',
      messages: [
        {
          id: '0d477f45-d375-450e-acbf-e51ec04fe364',
          type: 'MESSAGE',
          params: {
            bubbles: ['vibe.flow.rating.answerCritique'],
          },
        },
        {
          id: '7dbe54e2-7dbf-460e-8ebf-1ab796a23457',
          type: 'INTERACTION',
          params: {
            interactionType: 'INPUT',
            inputType: 'TEXTAREA',
            placeholder: ['vibe.general.placeholderFeedback'],
            dataKey: 'feedback',
            dispatchAction: 'board/submitFeedback',
          },
          appEvent: 'rating_critique',
        },
        {
          id: 'f70081ab-9a39-4190-9623-c9c9277e5da8',
          type: 'MESSAGE',
          params: {
            bubbles: ['vibe.flow.rating.outro'],
          },
        },
      ],
    },

    // 7.2 Rating 5 Stars
    {
      id: '20d5b02c-b8c5-416e-8e1e-acd8336a3d5d',
      messages: [
        {
          id: '26916042-5c15-4261-bd11-f4a1503b784f',
          type: 'MESSAGE',
          params: {
            bubbles: ['vibe.flow.rating.answerAppraisal'],
          },
        },
        {
          id: '592d76c5-79e0-4fa7-99c6-ac6d41610c9e',
          type: 'INTERACTION',
          params: {
            interactionType: 'INPUT',
            inputType: 'TEXTAREA',
            placeholder: ['vibe.general.placeholderFeedback'],
            dataKey: 'feedback',
            dispatchAction: 'board/submitFeedback',
          },
          appEvent: 'rating_appraisal',
        },
        {
          id: 'ca34da65-1477-4885-baf5-020871289e55',
          type: 'MESSAGE',
          params: {
            bubbles: ['vibe.flow.rating.outro'],
          },
        },
      ],
    },
  ],
};

export default messages;

export function getMessagesByBranchId(branchId) {
  try {
    const branch = messages.branches.find(
      (branchItr) => branchItr.id === branchId,
    );
    return branch.messages || [];
  } catch (err) {
    return [];
  }
}

// branch routers

const branchRouters = {
  '46b1d4ac-3773-4411-bd22-848b5a637368': (value) => {
    try {
      const parsedDate = dayjs(value, 'MMYYYY').startOf('month');
      const monthToDueDate = parsedDate.diff(dayjs(), 'month');

      if (monthToDueDate <= 18) {
        return '3c03f678-5d81-406a-9353-e9483bc5aef2';
      }
      return '70868554-400e-4b32-be56-e4eb7afc1fa5';
    } catch (err) {
      return '70868554-400e-4b32-be56-e4eb7afc1fa5';
    }
  },
  'ff6650e4-2584-4f85-a3ec-450adf7f8b20': (value) => {
    try {
      if (value === 5) {
        return '20d5b02c-b8c5-416e-8e1e-acd8336a3d5d';
      }
      return '6e75273f-52e8-457f-9d15-553234ee2702';
    } catch (err) {
      return '6e75273f-52e8-457f-9d15-553234ee2702';
    }
  },
};

export function getNextBranchByRouterId(branchRouterId, value) {
  try {
    const routerFunc = branchRouters?.[branchRouterId];
    if (!routerFunc || typeof routerFunc !== 'function') {
      return null;
    }
    return routerFunc(value);
  } catch (err) {
    console.log(err);
    return null;
  }
}
