import config from 'global-config';

/* eslint-disable */
function getBrowsers() {
  const browsers = [];

  try {
    // Opera 8.0+
    const isOpera =
      (!!window.opr && !!opr.addons) ||
      !!window.opera ||
      navigator.userAgent.indexOf(' OPR/') >= 0;

    // Firefox 1.0+
    const isFirefox = typeof InstallTrigger !== 'undefined';

    // Safari 3.0+ "[object HTMLElementConstructor]"
    const isSafari =
      /constructor/i.test(window.HTMLElement) ||
      (function (p) {
        return p.toString() === '[object SafariRemoteNotification]';
      })(
        !window['safari'] ||
          (typeof safari !== 'undefined' && safari.pushNotification),
      );

    // Internet Explorer 6-11
    const isIE = /*@cc_on!@*/ false || !!document.documentMode;

    // Edge 20+
    const isEdge = !isIE && !!window.StyleMedia;

    // Chrome 1 - 71
    const isChrome =
      !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

    // Blink engine detection
    const isBlink = (isChrome || isOpera) && !!window.CSS;

    if (isOpera) {
      browsers.push('opera');
    }
    if (isFirefox) {
      browsers.push('firefox');
    }
    if (isSafari) {
      browsers.push('safari');
    }
    if (isIE) {
      browsers.push('ie');
    }
    if (isEdge) {
      browsers.push('edge');
    }
    if (isChrome) {
      browsers.push('chrome');
    }
    if (isBlink) {
      browsers.push('blink');
    }
  } catch (e) {}

  return browsers;
}

/**
 * Return class for HTML element to implement browser
 * specific styles
 *
 * @return {[]string} class for html element
 */
export function getSwissLifeBrowserClasses() {
  const modifier = 'h-browser-device--';
  const browsers = getBrowsers().map((browser) => `${modifier}${browser}`);
  return browsers;
}

/**
 * Return class for HTML element to implement browser
 * specific styles
 *
 * @return {string} class for html element
 */
export function returnBrowserClass(isSiwssLife) {
  const modifier = isSiwssLife ? 'h-browser-device--' : 'eva-browser-';
  const browsers = getBrowsers().map((browser) => `${modifier}${browser}`);
  return browsers.join(' ') || '';
}

/**
 * Checks the query string for the given param
 *
 * @return {string} url param
 */
export function getUrlParam(name) {
  const results = new RegExp(`[?&]${name}=([^&#]*)`).exec(window.location.href);
  if (results === null) {
    return null;
  }
  return decodeURIComponent(results[1]) || null;
}

/**
 * Checks the query string for the given param and removed it
 *
 * @return {string} url
 */
export function removeUrlParam(sourceURL, key) {
  let rtn = sourceURL.split('?')[0],
    param,
    params_arr = [],
    queryString = sourceURL.indexOf('?') !== -1 ? sourceURL.split('?')[1] : '';
  if (queryString !== '') {
    params_arr = queryString.split('&');
    for (let i = params_arr.length - 1; i >= 0; i -= 1) {
      param = params_arr[i].split('=')[0];
      if (param === key) {
        params_arr.splice(i, 1);
      }
    }
    rtn = rtn + '?' + params_arr.join('&');
  }
  return rtn;
}

/**
 * Checks if the browser is IE11
 *
 * @return {bool}
 */
export function isIE() {
  try {
    return !!window.MSInputMethodContext && !!document.documentMode;
  } catch (i) {}
  return false;
}

/**
 * Cross browser viewport check
 *
 */
export function isElementInViewport(rawEl) {
  let el = rawEl;

  let top = el.offsetTop;
  let left = el.offsetLeft;
  const width = el.offsetWidth;
  const height = el.offsetHeight;

  while (el.offsetParent) {
    el = el.offsetParent;
    top += el.offsetTop;
    left += el.offsetLeft;
  }

  return (
    top >= window.pageYOffset &&
    left >= window.pageXOffset &&
    top + height <= window.pageYOffset + window.innerHeight &&
    left + width <= window.pageXOffset + window.innerWidth
  );
}

/**
 * Removed the hash from the url
 *
 * @return {string} url
 */
export function removeHash() {
  try {
    window.history.pushState(
      '',
      window.document.title,
      window.location.pathname + window.location.search,
    );
  } catch (ignore) {}
}

export function getLanguage() {
  let lang = config.defaultLanguage;

  // check for page language
  try {
    const htmlLang = document
      .querySelector('html')
      .getAttribute('lang')
      .substring(0, 2);

    if (config.languages.includes(htmlLang)) {
      lang = htmlLang;
    }
  } catch (e) {}

  // check widget params
  try {
    const paramLang = document
      .querySelector(`#${evaManifest.containerId}`)
      .getAttribute('data-eva-lang')
      .substring(0, 2);

    if (config.languages.includes(paramLang)) {
      lang = paramLang;
    }
  } catch (e) {}

  try {
    const language = getUrlParam('lang');
    if (language) {
      return language;
    }
  } catch (e) {}

  return lang.substring(0, 2);
}
