import styled from 'styled-components';

export const MessageHistoryContainer = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  list-style-type: none;

  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  scroll-behavior: smooth;
  overscroll-behavior: contain;
  -webkit-overflow-scrolling: touch;

  font-family: ${({ theme }) => theme.fontFamilyRegular};

  li:first-of-type {
    margin-top: 68px;
  }
`;
