/* eslint-disable */
import { createGlobalStyle } from 'styled-components/macro';

import media from 'utils/mediaQuery';
import theme from 'utils/theme';

const GlobalStyle = createGlobalStyle`
  #b67ef2a0483b {
    ${media.lessThan('small')`
      border-color: ${theme.colorBackground02};
    `};

    .eva-vibe-app-container {
      max-width: 600px;
      margin: auto;
    }

    .eva-vibe-board {
      max-height: 680px;
      overflow: auto;

      ${media.lessThan('small')`
        max-height: 480px;
      `};
    }
  }

  .b67ef2a0483b-standalone {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;

    .eva-vibe-app-container {
      background-color: ${(props) => props.theme.colorGrey07};
      width: 100vw;
    }
  }
`;

export default GlobalStyle;
