// Swiss Life Brand Colors
const brandColors = {
  colorBlack: '#000000',
  colorRed: '#d82034',
  colorBordeaux: '#a11c36',
  colorOrange: '#de822b',
  colorOcker: '#c4b300',
  colorViolet: '#6d1874',
  colorSilver: '#b8b8b8',

  // Additionals
  colorAnthrazit: '#6d1874',
  colorWhite: '#ffffff',
  colorGreen: '#22ac25',

  // Grey
  colorDarkGrey: '#515151',
  colorCustomGrey: '#ababab',
  colorGrey: '#808080',
  colorGrey01: '#666666',
  colorGrey02: '#808080',
  colorGrey03: '#b4b4b4',
  colorGrey04: '#e5e5e5',
  colorGrey05: '#f4f4f4',
  colorGrey06: '#d2d2d2',
  colorGrey07: '#f9f9f9',

  // EVA
  colorOckerCard: '#fcfcf4',
};

// Swiss Life Backgrounds
const backgrounds = {
  colorBackground01: '#353535',
  colorBackground02: '#515151',
  colorBackground03: '#666666',
  colorBackground04: '#808080',
  colorBackground05: '#b4b4b4',
  colorBackground06: '#e5e5e5',
  colorBackground07: '#f4f4f4',
  colorBackground08: '#d2d2d2',
  colorBackground09: '#f9f9f9',
  colorBackground10: '#ffffff',
  colorBackground11: '#22ac25',
};

// Swiss Life Mood
const moods = {
  // Violet
  colorVioletMood: brandColors.colorViolet,
  colorVioletMood100: brandColors.colorViolet,
  colorVioletMood80: '#8a4690',
  colorVioletMood60: '#a774ac',
  colorVioletMood40: '#c5a3c7',
  colorVioletMood20: '#e2d1e3',
  colorVioletMood10: '#f0e7f1',

  // Ocker
  colorOckerMood: brandColors.colorOcker,
  colorOckerMood100: brandColors.colorOcker,
  colorOckerMood80: '#cfc233',
  colorOckerMood60: '#dbd166',
  colorOckerMood40: '#e7e199',
  colorOckerMood20: '#f3f0cc',
  colorOckerMood10: '#f9f7e5',

  // Orange
  colorOrangeMood: brandColors.colorOrange,
  colorOrangeMood100: brandColors.colorOrange,
  colorOrangeMood80: '#e59b55',
  colorOrangeMood60: '#ebb480',
  colorOrangeMood40: '#f2cdaa',
  colorOrangeMood20: '#f8e6d5',
  colorOrangeMood10: '#fbf2e9',

  // Red
  colorRedMood: brandColors.colorRed,
  colorRedMood100: brandColors.colorRed,
  colorRedMood80: '#e04d5d',
  colorRedMood60: '#e87985',
  colorRedMood40: '#efa6ae',
  colorRedMood20: '#f7d2d6',
  colorRedMood10: '#fbe8ea',

  // Green
  colorGreenMood: '#1f9c22',
  colorGreenMood100: '#1f9c22',
  colorGreenMood80: '#4cb04e',
  colorGreenMood60: '#79c37a',
  colorGreenMood40: '#a5d7a6',
  colorGreenMood20: '#d2ebd3',
  colorGreenMood10: '#e8f5e8',
};

// Swiss Life Font Family
const fonts = {
  fontFamily: `'Calibri W02 Light', calibri, Arial, Helvetica, sans-serif`,
  fontFamilyRegular: `'Calibri W02 Regular', Arial, Helvetica, sans-serif`,
  fontFamilyBold: `'Calibri W02 Bold', Arial, Helvetica, sans-serif`,
  fontFamilyHeading: `'Legacy Serif ITC W02 Bk', Arial, Helvetica, sans-serif`,
  fontFamilyHeadingItalic: `'LegacySerifITCW02-BkIt',Arial,Helvetica,sans-serif`,
};

const settings = {
  // Card Styles
  paddingCard: '22px',
  paddingCardIwr: '32px',
};

const general = {
  colorText: backgrounds.colorBackground01,

  // Intro
  colorIntroHeaderBackground: '#9DBDBC',
  colorIntroHeaderText: backgrounds.colorBackground01,
  colorIntroHeaderStepIncomplete: '#88afad',
  colorIntroHeaderStepCompleted: brandColors.colorWhite,

  // Child care
  colorChildCareParents: '#808080',
  colorChildCareOther: '#A5D7A6',
  colorChildCareChargeable: '#EFA6AE',
  colorChildCareSummary: brandColors.colorGrey05,

  // Forms
  colorBorderInput: brandColors.colorSilver,

  // Cards
  colorCardEditBudget: '#e5e0a1',
  colorCardEditBudgetNegative: '#dddddd',

  colorCardSavings: moods.colorGreenMood20,
  colorCardSavingsNegative: '#F9DEE1',

  // Save Card
  colorItemIcon: '#231f20',

  // ViBe
  vibeOfferBackgroundColor: '#9ebdbc',
  vibeOfferThumbsUpBorder: '1px solid #00000030',

  vibeRadioBorderColor: '#aaaaaa',
  vibeRadioBackgroundColorHover: '#aaaaaa',

  vibeMessageBackgroundColorActive: '#ccd199',
};

const theme = {
  ...brandColors,
  ...backgrounds,
  ...moods,
  ...fonts,
  ...settings,
  ...general,
};

export default theme;
