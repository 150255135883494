import styled from 'styled-components';
import media from 'utils/mediaQuery';

export const OfferContainer = styled.div`
  width: 100%;
  padding-left: 36px;
  padding-right: 36px;

  ${media.lessThan('small')`
    padding-left: 0px;
    padding-right: 0px;
  `}
`;
