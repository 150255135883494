import styled, { css } from 'styled-components';

import mediaQuery from 'utils/mediaQuery';

const { lessThan } = mediaQuery;

export const ChatBoxContainer = styled.div`
  display: flex;
  width: 100%;

  padding-left: 32px;
  padding-right: 32px;
  padding-top: 8px;
  margin-top: auto;

  position: relative;

  ${lessThan('medium')`
    padding-left: 12px;
    padding-right: 12px;
  `};

  ${({ hasError, theme }) =>
    hasError &&
    css`
      & > input {
        border-color: ${theme.colorRed};
      }
      & > input:focus {
        outline: none;
        outline-color: ${theme.colorRed};
        border-color: ${theme.colorRed};
      }
    `}
`;

export const Input = styled.input`
  width: 100%;
  margin-right: 16px;
  border-radius: 5px;
  padding: 16px;
  border: ${({ theme }) => `1px solid ${theme.colorGrey06}`};
`;

export const TextArea = styled.textarea`
  width: 100%;
  margin-right: 16px;
  border-radius: 5px;
  padding: 16px;
  border: ${({ theme }) => `1px solid ${theme.colorGrey06}`};
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  margin: auto;
  width: 36px;
  height: 36px;
  padding: 8px;
  border: 0;
  border-radius: 2px;
  background-color: ${({ theme }) => theme.colorRed};

  font-size: 16px;
  color: white;

  transition: 150 ease-in all;
  &:disabled {
    background-color: ${({ theme }) => theme.colorGrey06};
  }
`;

export const ErrorText = styled.div`
  font-family: ${({ theme }) => theme.fontFamilyRegular};
  color: ${({ theme }) => theme.colorRed};
  width: 100%;
  padding-top: 8px;
  padding-left: 32px;
  padding-right: 32px;
  ${lessThan('medium')`
    padding-left: 12px;
    padding-right: 12px;
  `}
`;
