import Slider from 'rc-slider';

import { handleRender } from './HandleTooltip';
import { SliderContainer } from './Slider.style';

function VibeSlider({
  min = 1,
  max = 25,
  marks = { 1: 1, 25: 25 },
  sliderClassName,
  ...props
}) {
  return (
    <SliderContainer className={sliderClassName}>
      <Slider
        {...{ min, max, marks }}
        {...props}
        handleRender={(...props) => handleRender(...props, sliderClassName)}
      />
    </SliderContainer>
  );
}

export default VibeSlider;
