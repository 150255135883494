import { useEffect, useState } from 'react';

export default function useTimer(interval, start, end) {
  const [value, setValue] = useState(start);
  const [intervalId, setIntervalId] = useState(null);

  useEffect(() => {
    const id = setInterval(() => {
      setValue((c) => c + 1);
    }, interval);
    setIntervalId(id);
    return () => clearInterval(id);
  }, [interval]);

  useEffect(() => {
    if (value >= end) clearInterval(intervalId);
  }, [value, end, intervalId]);

  return value;
}
