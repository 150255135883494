import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import messageDatabase, {
  getMessagesByBranchId,
  getNextBranchByRouterId,
} from 'message-database';
import { scrollToBottomOfMessageHistory } from 'utils/scroll';
import evaTracking from 'utils/evaTracking';

import { fetchHypoCheckDocument } from 'features/Offer/Offer.slice';

import { findMessageAndPosition } from './board.helpers';
import validateAndFormat from './board.validator';
import parseData from './board.parser';
import {
  submitConsultationRequest as apiSubmitConsultationRequest,
  submitFeedback as apiSubmitFeedback,
  submitReminderRequest as apiSubmitReminderRequest,
} from './board.api';

const initialMessages = getMessagesByBranchId(messageDatabase.entrypoint);

const initialState = {
  branchId: messageDatabase.entrypoint,
  messages: [],
  personalData: {},
  calculationData: {},
  inputSettings: {
    editingMessageId: null,
    isActive: false,
    isValid: false,
    hasError: false,
    errorMessage: false,
    placeholder: '',
    value: '',
  },
  isModalOpen: false,
};

export const submitConsultationRequest = createAsyncThunk(
  'board/submitConsultationRequest',
  async (_, { getState }) => {
    const response = await apiSubmitConsultationRequest(getState());
    return response;
  },
);

export const submitReminderRequest = createAsyncThunk(
  'board/submitReminderRequest',
  async (eMail, { getState }) => {
    const response = await apiSubmitReminderRequest(getState(), eMail);
    return response;
  },
);

export const submitFeedback = createAsyncThunk(
  'board/submitFeedback',
  async (feedbackText, { getState }) => {
    await apiSubmitFeedback(getState(), feedbackText);
    return this;
  },
);

export const boardSlice = createSlice({
  name: 'board',
  initialState,
  reducers: {
    completeInteraction: (state, action) => {
      const { value, messageId } = action.payload || {};

      const [message, messageIdx] = findMessageAndPosition(
        messageId,
        state.messages,
      );

      if (
        !message?.id ||
        (message?.type !== 'INTERACTION' && message?.type !== 'RATING')
      ) {
        return;
      }

      const [formattedValue, isValid, errorMessage] = validateAndFormat(
        value,
        message,
        state,
      );

      // if it is not valid, pause process and update errorMessage value
      if (!isValid) {
        state.inputSettings.errorMessage = errorMessage || true;
        return;
      }

      // disable the input for processing
      if (state.inputSettings.messageId === messageId) {
        state.inputSettings.errorMessage = false;
        state.inputSettings.isActive = false;
      }

      // as first step we use the gathered data and extend our personal data
      const { dataKey } = message?.params || {};
      if (dataKey) {
        parseData(state, dataKey, formattedValue);
      }

      // now we can mutate the message in the message history
      if (messageIdx === -1) {
        return;
      }

      // set the values and update the state
      state.messages[messageIdx].value = value;
      state.messages[messageIdx].isCompleted = true;

      // purge the input field
      if (state.inputSettings.messageId === messageId) {
        state.inputSettings.value = '';
      }
    },
    startFlow: (state, _) => {
      state.messages = [initialMessages[0]];
      evaTracking.appEvent('flow_start');
    },
    continueFlow: (state, action) => {
      const { messageId, value } = action.payload;
      const [message, messageIdx] = findMessageAndPosition(
        messageId,
        state.messages,
      );

      const currentBranchId = state.branchId || messageDatabase.entrypoint;
      const branchMessages = getMessagesByBranchId(currentBranchId);
      const currentMessage = selectCurrentMessage(state);

      // if there is no current message, we just push the first one
      if (!currentMessage?.id) {
        state.messages.push({ ...branchMessages[0] });
        return;
      }

      const currentMessageBranchIdx = branchMessages?.findIndex(
        (messageItr) => messageItr.id === currentMessage.id,
      );
      const currentMessageIdx = state.messages?.findIndex(
        (messageItr) => messageItr.id === currentMessage.id,
      );

      // the CALENDAR widget is a special case because it does not influence
      // the shared message box and thus should only change the flow if it is the current
      // interaction and not an edit
      if (
        messageIdx !== currentMessageIdx &&
        message?.params?.interactionType === 'CALENDAR'
      ) {
        return;
      }

      // reset the editing message and value
      const isEdit = state.inputSettings.editingMessageId !== null;
      state.inputSettings.editingMessageId = null;
      state.inputSettings.value = '';

      let nextMessage = null;

      // check if it is a normal message (not a branch switch)
      if (!message?.params?.isBranchSwitch) {
        nextMessage = {
          ...branchMessages?.[currentMessageBranchIdx + 1],
          branchId: currentBranchId,
        };
      } else {
        // switch the branch in here
        let nextBranchId = value;
        if (message?.params?.branchRouterId) {
          nextBranchId = getNextBranchByRouterId(
            message?.params?.branchRouterId,
            value,
          );
        }
        if (message?.params?.branchId) {
          nextBranchId = message?.params?.branchId;
        }

        const nextBranchMessages = getMessagesByBranchId(nextBranchId);
        if (!nextBranchMessages || !nextBranchMessages?.length) {
          console.error('invalid branch', nextBranchId);
          return;
        }

        nextMessage = { ...nextBranchMessages?.[0], branchId: nextBranchId };

        // if the branch switch is not the most recent message, we should clear all the messages
        // that happened after the previous branch switch
        const messagePosition = state.messages.findIndex(
          (messageItr) => messageItr.id === messageId,
        );

        if (messagePosition < state.messages.length - 1) {
          state.messages = state.messages.slice(0, messagePosition + 1);
        }
      }

      if (!nextMessage?.id) {
        console.error('empty next message');
        return;
      }

      // after all the logic to find the next message we need to check at the end if all was just
      // an edit to an existing message with no consequence for the flow. If this is the case
      // we can just remove the last message and push it again. This will automatically reset the
      // ChatBox state and also makes sure the flow continues correctly
      if (state.branchId === nextMessage.branchId && isEdit) {
        nextMessage = state.messages.pop();
      }

      // push message to the mesage history and update branch
      state.branchId = nextMessage.branchId;
      state.messages.push({ ...nextMessage, branchId: state.branchId });
      evaTracking.trackMessage(nextMessage, false);

      // check if the next message should activate the input
      if (nextMessage?.params?.interactionType !== 'INPUT') {
        state.inputSettings.isActive = false;
        state.inputSettings.placeholder = '';
        return;
      }

      state.inputSettings.messageId = nextMessage.id;
      state.inputSettings.message = nextMessage;
      state.inputSettings.isActive = true;
      state.inputSettings.isValid = false;
      state.inputSettings.placeholder = nextMessage.params.placeholder;
      state.inputSettings.inputType = nextMessage.params.inputType;
      state.inputSettings.value = nextMessage.value || '';
    },
    startEditMessage: (state, action) => {
      const { messageId, replacementValue } = action.payload;

      // save selected message id
      state.inputSettings.messageId = messageId;
      state.inputSettings.editingMessageId = messageId;

      // initialize inputSettings, set input value as the previous value of the selected message
      const selectedMessage = state.messages.find(
        (msg) => msg.id === messageId,
      );
      state.inputSettings.isActive = true;
      state.inputSettings.errorMessage = false;
      state.inputSettings.placeholder = selectedMessage.params.placeholder;
      state.inputSettings.inputType = selectedMessage.params.inputType;
      state.inputSettings.value = replacementValue || selectedMessage.value;
    },
    changeInputSettingsValue: (state, action) => {
      const messageId =
        state.inputSettings.editingMessageId || state.inputSettings.messageId;
      const [message] = findMessageAndPosition(messageId, state.messages);
      const [value, isValid] = validateAndFormat(
        action.payload,
        message,
        state,
      );
      state.inputSettings.value = value;
      state.inputSettings.isValid = isValid;
      if (isValid) {
        state.inputSettings.hasError = false;
        state.inputSettings.errorMessage = false;
      }
    },
    changeInputSettingsIsActive: (state, action) => {
      state.inputSettings.isActive = action.payload;
    },
    changeInputSettingsValidaton: (state, action) => {
      const { isValid, errorMessage } = action.payload;

      state.inputSettings.isValid = isValid;
      state.inputSettings.hasError = !isValid;
      state.inputSettings.errorMessage = errorMessage;
    },
    scrollToBottom: () => {
      scrollToBottomOfMessageHistory();
    },
    setMessageIsLoading: (state, action) => {
      const { messageId, isLoading } = action.payload;

      const currentMessageIdx = state.messages?.findIndex(
        (messageItr) => messageItr.id === messageId,
      );

      if (!state.messages?.[currentMessageIdx]) {
        return;
      }
      state.messages[currentMessageIdx].isLoading = isLoading;
    },
    changeIsModalOpen: (state, action) => {
      if (window.EVA_VIBE_MODAL_MODE && action.payload === true) {
        return;
      }
      state.isModalOpen = action.payload;
    },
  },
});

export default boardSlice.reducer;

// Selectors
export const selectMessages = (state) => state.board.messages;
export const selectPersonalData = (state) => state.board.personalData;
export const selectCalculationData = (state) => state.board.calculationData;
export const selectInputSettings = (state) => state.board.inputSettings;
export const selectCurrentMessage = (state) => {
  const safeMessages = state?.messages || state?.board?.messages || [];
  if (safeMessages?.length < 1) {
    return null;
  }
  return safeMessages?.[safeMessages?.length - 1] || null;
};
export const selectIsModalOpen = (state) => state.board.isModalOpen;

// Actions
export const {
  continueFlow,
  startFlow,
  changeInputSettingsIsActive,
  changeInputSettingsValue,
  changeInputSettingsValidaton,
  changeIsModalOpen,
  setMessageIsLoading,
  scrollToBottom,
} = boardSlice.actions;

const { completeInteraction, startEditMessage } = boardSlice.actions;

// submitMessage is the main method called by the ChatBox input field
// it should parse the user input based on the active message and also continue with the flow
export const submitMessage =
  (messageId, value) => async (dispatch, getState) => {
    dispatch(userInteraction());

    const messages = selectMessages(getState());
    const currentMessage = selectCurrentMessage(getState());
    const inputSettings = selectInputSettings(getState());
    const [message] = findMessageAndPosition(messageId, messages);

    evaTracking.trackMessage(
      message,
      true,
      message.appEventIncludeValue ? value : '',
    );

    const [formattedValue, isValid, errorMessage] = validateAndFormat(
      value,
      message,
      getState().board,
    );

    // if it is not valid, pause process and update errorMessage value
    if (!isValid) {
      dispatch(changeInputSettingsValidaton({ isValid, errorMessage }));
      return;
    }

    // check if message has action to dispatch
    if (message?.params?.dispatchAction) {
      switch (message.params.dispatchAction) {
        case 'board/submitConsultationRequest':
          dispatch(submitConsultationRequest());
          break;
        case 'board/submitReminderRequest':
          dispatch(submitReminderRequest(formattedValue));
          break;
        case 'board/submitFeedback':
          dispatch(submitFeedback(formattedValue));
          break;
        default:
          console.log('invalid dispatchAction', message.params.dispatchAction);
      }
    }

    // check if the message should trigger a document download
    const selectedOption = message?.params?.options?.find(
      (optionItr) => optionItr?.value === value,
    );
    if (selectedOption && selectedOption?.triggerDownload) {
      dispatch(fetchHypoCheckDocument(messageId));
    }

    await dispatch(
      completeInteraction({
        messageId,
        value,
      }),
    );

    const isCurrentMessage = currentMessage?.id === message?.id;
    if (isCurrentMessage || inputSettings?.editingMessageId) {
      dispatch(
        continueFlow({
          messageId,
          value,
        }),
      );
    }
    return this;
  };

export const editMessage =
  (messageId, replacementValue) => async (dispatch) => {
    dispatch(userInteraction());
    dispatch(startEditMessage({ messageId, replacementValue }));
  };

export const userInteraction = () => async (dispatch, getState) => {
  const isModalOpen = selectIsModalOpen(getState());
  if (isModalOpen) {
    return;
  }
  if (window.innerWidth > 450) {
    return;
  }
  dispatch(changeIsModalOpen(true));
};
