import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import NumberFormat from 'react-number-format';
import dayjs from 'dayjs';

import thousandSeparator from 'utils/thousandSeparator';
import { Typography, Bubble, SpriteIcon } from 'components';
import { editMessage } from 'features/Board/board.slice';
import { scrollIntoView } from 'utils/scroll';
import {
  InteractionContainer,
  SpriteIconContainer,
} from './InteractionInput.style';

function InteractionInput({ message }) {
  const { isCompleted, value, params, id } = message;
  const dispatch = useDispatch();

  // we only render the answer if the interaction is completed
  if (!isCompleted) {
    return <></>;
  }

  let messageContent = <Typography>{value}</Typography>;
  if (params.inputType === 'CURRENCY') {
    messageContent = (
      <NumberFormat
        prefix="CHF "
        thousandSeparator={thousandSeparator(value)}
        displayType="text"
        renderText={(value) => <Typography>{value}</Typography>}
        value={value}
      />
    );
  }

  if (params.inputType === 'DATE') {
    const parsedDate = dayjs(value, 'DDMMYYYY');
    messageContent = (
      <Typography>{parsedDate.format('DD. MMMM YYYY')}</Typography>
    );
  }

  if (params.inputType === 'SHORT_DATE') {
    const parsedDate = dayjs(value, 'MMYYYY');
    messageContent = <Typography>{parsedDate.format('MMMM YYYY')}</Typography>;
  }

  const onClickEdit = () => {
    setTimeout(() => scrollIntoView(id), 0);
    dispatch(editMessage(id));
  };

  return (
    <Bubble position="right">
      <InteractionContainer>
        {messageContent}
        <SpriteIconContainer onClick={onClickEdit}>
          <SpriteIcon name="action-edit-20" />
        </SpriteIconContainer>
      </InteractionContainer>
    </Bubble>
  );
}

InteractionInput.propTypes = {
  message: PropTypes.object.isRequired,
};

export default React.memo(InteractionInput);
