import { Loader, Dot } from './TypingLoader.style';

const TypingLoader = () => {
  return (
    <Loader>
      <Dot scale="0.8" />
      <Dot scale="0.5" />
      <Dot scale="0.5" />
    </Loader>
  );
};
export default TypingLoader;
