import styled, { css } from 'styled-components';
import media from 'utils/mediaQuery';

const getMainBlackColor01 = (props) => props.theme.colorBackground01;

export const getSliderStyle = (theme) => {
  const height = 10;
  const dotHeight = 20;
  return {
    trackStyle: {
      backgroundColor: getMainBlackColor01({ theme }),
      height,
    },
    railStyle: {
      height,
    },
    handleStyle: {
      height: dotHeight,
      width: dotHeight,
      backgroundColor: theme.colorBackground01,
      borderColor: theme.colorBackground01,
      opacity: 1,
    },
    dotStyle: {
      opacity: 0,
    },
  };
};

export const OfferContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const LoaderContainer = styled.div`
  .spinner-layer {
    border-color: ${({ theme }) => theme.colorWhite};
  }
`;

export const LoaderText = styled.p`
  color: ${({ theme }) => theme.colorBlack};
  font-family: ${({ theme }) => theme.fontFamilyRegular};
  font-size: 16px;
  line-height: 20px;
  max-width: 190px;
  margin: auto;
  text-align: center;
`;

export const Main = styled.div`
  gap: 18px;
  background-color: ${({ theme }) => theme.vibeOfferBackgroundColor};
  padding: 30px;
  border-radius: 10px;
  width: 100%;
  position: relative;

  ${({ knockout }) =>
    knockout &&
    css`
      display: flex;
      padding: 20px;
    `};

  ${media.lessThan('small')`
    padding: 15px;
  `};
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  width: 20px;
  height: 20px;
  padding: 2px;
  position: relative;
  flex-shrink: 0;
  place-content: center;
  border-radius: 100%;
`;

export const TextContainer = styled.div`
  display: flex;
  gap: 18px;
  flex-direction: column;
  color: black;
`;

export const Description = styled.p`
  font-family: ${({ theme, bold }) =>
    bold ? theme.fontFamilyBold : theme.fontFamilyRegular};
  font-size: 16px;
  line-height: 22px;

  .number {
    font-family: ${({ theme }) => theme.fontFamilyBold};
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;

  ${media.lessThan('small')`
    margin-bottom: 15px;
  `}

  ${({ isSticky }) =>
    isSticky &&
    css`
      ${media.lessThan('small')`
        margin-bottom: 0px;
        & > p {
          display: none;
        }
        & > svg {
          position: absolute;
          right: 16px;
          top: 18px;
        }
    `};
    `}

  ${({ isCollapsed }) =>
    !isCollapsed &&
    css`
      ${media.lessThan('small')`
        margin-bottom: 15px;
        & > p {
          display: flex;
        }
    `};
    `}
`;

export const Title = styled.p`
  color: ${({ theme }) => theme.colorBlack};
  font-family: ${({ theme }) => theme.fontFamilyHeading};
  font-size: 20px;
  line-height: 24px;
  margin: 0;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  gap: 4px;

  ${media.lessThan('small')`
    flex-direction: column;
  `};
`;

const getMarginBottom = ({ isCollapsed }) => (isCollapsed ? 0 : '30px');
const getFlexDirection = ({ isSticky, isCollapsed }) =>
  isCollapsed && !isSticky ? 'column' : 'row';
const getAlignItems = ({ isSticky, isCollapsed }) =>
  isCollapsed && !isSticky ? 'flex-start' : 'flex-end';

export const FigureContainer = styled.div`
  display: flex;
  margin-bottom: ${getMarginBottom};
  & > div {
    flex-direction: ${getFlexDirection};
    align-items: ${getAlignItems};

    ${media.lessThan('small')`
      flex-direction: column;
      align-items: flex-start;
    `};
  }
`;

export const FigureItem = styled.div`
  display: flex;
  width: 50%;
  padding-left: 17px;

  &:not(:last-of-type) {
    padding-left: 0px;
    border-style: solid;
    border-width: 0px 2px 0px 0px;
    border-color: ${getMainBlackColor01};
  }

  &.last {
    border: none;
    padding-left: 22px;
  }
`;

export const ItalicLabel = styled.div`
  font-family: ${({ theme }) => theme.fontFamilyHeadingItalic};
  font-size: 16px;
  color: ${getMainBlackColor01};
  margin-right: 12px;
`;

export const FigureValue = styled.div`
  font-size: 36px;
  font-family: Calibri;
  line-height: 32px;
  color: black;

  ${media.lessThan('small')`
    font-size: 24px;
  `};
`;

export const ThumbsUpButton = styled.button`
  display: flex;
  align-items: center;

  padding: 12px 18px;
  margin-top: 12px;

  cursor: pointer;
  border-radius: 10px;
  border: ${({ theme }) => theme.vibeOfferThumbsUpBorder};
  background-color: ${({ theme }) => theme.colorWhite};

  font-family: ${({ theme }) => theme.fontFamilyHeading};

  transition: 150ms ease-in all;
  &:hover {
    border-color: ${({ theme }) => theme.vibeRadioBackgroundColorHover};
    background-color: ${({ theme }) => theme.vibeRadioBackgroundColorHover};
    color: ${({ theme }) => theme.colorWhite};

    div {
      background-color: ${({ theme }) => theme.colorWhite} !important;
    }

    p {
      color: ${({ theme }) => theme.colorWhite} !important;
    }
  }
`;

export const ThumbsUpIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 28px;
  height: 28px;

  padding: 2px;
  border-radius: 100%;
  margin-right: 12px;

  background-color: ${({ theme }) => theme.colorBackground08};
  font-size: 16px;
  color: ${({ theme }) => theme.colorBackground01};
`;

export const SliderContainer = styled.div`
  margin-top: 48px;
  margin-bottom: 16px;

  .rc-slider-mark-text {
    color: ${({ theme }) => theme.colorBackground01};
    font-size: 16px;
    line-height: 20px;
    margin-top: 6px;
  }

  .rc-tooltip-inner {
    white-space: nowrap;
  }
`;

export const DisclaimerContainer = styled.div`
  display: flex;
  flex: 100% 1 0;
  margin-top: 45px;

  .a-svg {
    font-size: 20px;
    min-height: 20px;
    min-width: 20px;
    margin-right: 13px;
    color: ${({ theme }) => theme.colorBlack};
  }

  .content {
    font-size: 16px;
    line-height: 22px;
    white-space: pre-line;
  }
`;

export const DesktopActionWrapper = styled.div`
  display: flex;
  cursor: pointer;

  ${media.lessThan('small')`
    display: none;
  `};
`;

export const MobileActionWrapper = styled.div`
  display: flex;
  cursor: pointer;

  ${media.greaterThan('small')`
    display: none;
  `};
`;

export const ActionWrapper = styled.div`
  display: flex;
  cursor: pointer;
`;
