import styled from 'styled-components';
import { motion } from 'framer-motion';

import { isLeft } from './helpers';

const getAlignItems = ({ position }) =>
  isLeft(position) ? 'flex-start' : 'flex-end';
const getMarginBottom = ({ position }) => (isLeft(position) ? '12px' : '32px');

export const MessageContainer = styled(motion.li)`
  display: flex;
  position: relative;
  margin-bottom: ${getMarginBottom};
  align-items: flex-end;
  width: 100%;
`;

export const BubbleContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: ${getAlignItems};

  &.is-active {
    .eva-vibe-bc:last-of-type {
      background-color: ${({ theme }) =>
        theme.vibeMessageBackgroundColorActive};
      &::before {
        border-right-color: ${({ theme }) =>
          theme.vibeMessageBackgroundColorActive};
      }
    }
  }
`;

export const ProfilePictureContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 32px;
  height: 32px;
  margin-right: 16px;
  border-radius: 50%;

  color: ${({ theme }) => theme.colorWhite};

  .a-svg {
    width: 16px;
    height: 16px;
  }
`;
