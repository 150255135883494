import { getUrlParam } from 'utils/browser';

const prodBaseUrl = 'https://api-ng.eventually.cloud';
const devBaseUrl = 'https://s-api.eventually.cloud';
const localBaseUrl = 'http://localhost:4005';

export const API_URL_PROD = `${prodBaseUrl}/api`;
export const API_URL_DEV = `${devBaseUrl}/api`;
export const API_URL_LOCAL = `${localBaseUrl}/api`;

let baseUrl = prodBaseUrl;

// detect dev
let isDev = false;
try {
  isDev = getUrlParam('evadev') === 'true' || getUrlParam('evaenv') === 'dev';
} catch (e) {}

if (isDev) {
  baseUrl = devBaseUrl;
}

// detect local
if (window.location.host.includes('localhost')) {
  baseUrl = devBaseUrl;
}

const backendConfigObj = {
  baseUrl,
  apiUrl: `${baseUrl}/api`,
};

const config = {
  backend: backendConfigObj,
  defaultBreakpoint: 'medium',
  defaultLanguage: 'de',
  languages: ['de', 'fr', 'it', 'en'],
};
export default config;
