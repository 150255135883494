import dayjs from 'dayjs';

export default function boardValueParser(state, dataKey, value) {
  switch (dataKey) {
    // Personal Data
    case 'fullName': {
      let splittedName = value?.split(',');
      if (splittedName?.length < 2) {
        splittedName = value?.split(' ');
      }
      state.personalData.fullName = value;
      state.personalData.firstName = splittedName?.[0]?.trim();
      state.personalData.lastName = splittedName?.[1]?.trim() || 'N/A';
      break;
    }
    case 'consultationLocation': {
      state.personalData.location = value;
      break;
    }
    case 'consultationEMail': {
      state.personalData.eMail = value;
      break;
    }
    case 'consultationPhoneNumber': {
      state.personalData.phoneNumber = value;
      break;
    }
    case 'consultationDate': {
      const parsedDueDate = dayjs(value, 'DDMMYYYY', true);
      if (parsedDueDate.isValid()) {
        state.personalData.consultationDate = parsedDueDate.toISOString();
      }
      break;
    }
    // Calculation Data
    case 'dueDate': {
      const parsedDueDate = dayjs(value, 'MMYYYY');
      if (parsedDueDate.isValid()) {
        state.calculationData.dueDate = parsedDueDate.toISOString();
      }
      break;
    }
    case 'postalCode': {
      state.calculationData.postalCode = value;
      break;
    }
    case 'mortgageValue':
    case 'grossYearlyIncome':
    case 'marketValue': {
      state.calculationData[dataKey] = parseInt(value, 10);
      break;
    }
    default:
      state.personalData[dataKey] = value;
      break;
  }
}
