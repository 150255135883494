import styled, { css } from 'styled-components';

import { ReactComponent as Star } from 'assets/icons/star.svg';

import mediaQuery from 'utils/mediaQuery';
const { lessThan } = mediaQuery;

export const RatingContainer = styled.div`
  display: flex;
  direction: rtl;
  &:hover {
    svg {
      fill: ${({ theme }) => theme.colorGrey};
      &:hover {
        fill: ${({ theme }) => theme.colorRed};
      }
    }
  }
`;

export const StarIconContainer = styled.div`
  &:hover,
  &:hover ~ div,
  &:focus,
  &:focus ~ div {
    svg {
      fill: ${({ theme }) => theme.colorRed};
    }
    cursor: pointer;
  }
  padding: 6px 15px;
  ${lessThan('small')`
    padding: 6px;
  `};
  display: flex;
  align-items: center;
`;

export const StarIcon = styled(Star)`
  ${({ selected, theme }) => css`
    fill: ${selected ? theme.colorRed : theme.colorGrey};
  `}
  width: 40px;
  height: 40px;
  ${lessThan('medium')`
    width: 30px;
    height: 30px;
  `};
`;
