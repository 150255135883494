import { useLayoutEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { Bubble } from 'components';

import { Offer } from 'features/Offer';

import {
  isMessage,
  isOffer,
  isSelect,
  isInput,
  isCalendar,
  isMonthPicker,
  isRating,
} from '../../helpers';

import { SimpleMessage } from '../SimpleMessage';
import { Radio } from '../Radio';
import { InteractionInput } from '../InteractionInput';
import { OfferContainer } from './MessageContent.style';
import { Calendar } from '../Calendar';
import { Rating } from '../Rating';
import MonthPicker from '../MonthPicker/MonthPicker';

function MessageContent({ isCurrent, message, position }) {
  const { type, params } = message;
  const { interactionType, inputType } = params || {};
  const offerRef = useRef(null);

  useLayoutEffect(() => {
    if (!offerRef) return;
  }, [offerRef]);

  if (isMessage(type)) {
    return <SimpleMessage message={message} position={position} />;
  }

  if (isSelect(type, interactionType)) {
    return (
      <Bubble position={position}>
        <Radio message={message} isCurrent={isCurrent} />
      </Bubble>
    );
  }

  if (isCalendar(type, interactionType, inputType)) {
    return (
      <Bubble position={position}>
        <Calendar message={message} />
      </Bubble>
    );
  }

  if (isMonthPicker(type, interactionType, inputType)) {
    return (
      <>
        <Bubble position={position}>
          <MonthPicker message={message} />
        </Bubble>
      </>
    );
  }

  if (isRating(type)) {
    return (
      <Bubble position={position}>
        <Rating message={message} />
      </Bubble>
    );
  }

  if (isInput(type, interactionType)) {
    return <InteractionInput message={message} />;
  }

  if (isOffer(type)) {
    return (
      <OfferContainer ref={offerRef}>
        <Offer message={message} />
      </OfferContainer>
    );
  }
  return <></>;
}

MessageContent.propTypes = {
  message: PropTypes.object.isRequired,
  position: PropTypes.oneOf(['left', 'right']),
  isCurrent: PropTypes.bool,
};

export default MessageContent;
