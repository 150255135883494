import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { AnimatePresence } from 'framer-motion';

import { Offer } from 'features/Offer';
import { selectIsStickyOfferVisible } from 'features/Offer/Offer.slice';

import { StickContainer } from './StickyOffer.style';

function StickyOffer({ isModal }) {
  const isStickyOfferVisible = useSelector(selectIsStickyOfferVisible);

  let yOffset = 0;
  if (isModal) {
    yOffset = document?.querySelector('#eva-vibe-header')?.clientHeight || 63;
  }

  return (
    <AnimatePresence>
      {isStickyOfferVisible && (
        <StickContainer
          initial={{ opacity: 0, y: -500 }}
          animate={{ opacity: 1, y: yOffset }}
          exit={{ opacity: 0, y: -500 }}
        >
          <Offer isSticky />
        </StickContainer>
      )}
    </AnimatePresence>
  );
}

StickyOffer.propTypes = {
  isModal: PropTypes.bool,
};

export default StickyOffer;
