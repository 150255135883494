import { BubbleContainer } from './Bubble.style';

const Bubble = ({ position, isLast, isLoading, children }) => {
  return (
    <BubbleContainer
      className="eva-vibe-bc"
      position={position}
      isLast={isLast}
      isLoading={isLoading}
    >
      {children}
    </BubbleContainer>
  );
};

export default Bubble;
