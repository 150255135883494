import styled from 'styled-components';

export const BoardContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  width: 100%;
  height: calc(100vh - 84px);

  position: relative;
  padding: 0 8px 36px 8px;

  background-color: white;
`;
