function SpriteIcon({ name }) {
  return (
    <svg className={`a-svg a-svg--icon a-svg--${name}`}>
      <use
        xlinkHref={`/etc.clientlibs/slevo/clientlibs/site/resources/sprites.svg#${name}`}
      />
    </svg>
  );
}

export default SpriteIcon;
